import translate from "../../../translation";
import { ICart } from "../../../types/tracker.types";
import style from "./cartMiniature.module.scss";
import getStatusColor from "../../../translation/statusColor";
import { useModalComponent } from "../../../context/modalComponent.context";
import CartModal from "../../modals/cartModal/cartModal";
import { useHideSideBar } from "../../../context/hideSideBar.context";

export default function CartMiniature({ data }: { data: ICart }) {
  const { setModalIsOpen, setModalChildren } = useModalComponent();
  const { setHideSide } = useHideSideBar();

  function openCartModal() {
    setModalIsOpen(true);
    setModalChildren(<CartModal id={data.documentId} />);
    // setHideSide(true);
  }

  return (
    <div className={style["cart-miniature"]}>
      {data && data.documentId.toString() !== "" ? (
        <div
          onClick={openCartModal}
          className={style["cart-miniature-container"]}
        >
          <div className={style["top-container"]}>
            <div className={style["header-container"]}>
              <div className={style["title"]}>Panier</div>
              <div
                className={style["state"]}
                style={{ backgroundColor: `${getStatusColor(data.state)}` }}
              >
                {translate(data.state)}
              </div>
            </div>
            <div className={style["body-container"]}>
              <div className={style["info-line"]}>
                <span>Date de création</span>
                <span>
                  {new Date(data.createdAt).toLocaleDateString("fr-FR")}
                </span>
              </div>
              <div className={style["info-line"]}>
                <span>Montant</span>

                {isNaN(Math.floor(data.amount * 100) / 100) ? (
                  <span className={style["unavailable"]}>{"indisponible"}</span>
                ) : (
                  <span>{`${Math.floor(data.amount * 100) / 100} €`}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={style["add-new-container"]}>
          <button className={style["add-new"]} onClick={() => {}}>
            Générer un panier
          </button>
        </div>
      )}
    </div>
  );
}
