import { FormEvent, useEffect, useMemo, useState } from "react";
import InputContainer from "../../../../../components/forms/inputs/inputContainer/inputContainer";
import AutoCompleteInput from "../../../../../components/forms/logisticForm/autoCompleteInput/autoCompleteInput";
import { deleteFileFromLogistic, fetchBrands, fetchCategories } from "../../../../../requests/logistic";
import {
  ICommentObject,
  IContact,
  IProduct,
} from "../../../../../types/logistic.types";
import {
  OPERATION_GROUP,
  PRODUCT_GROUP,
} from "../../../../../utils/logistic.init";
import style from "../cartToLogistic.module.scss";
import Select from "../../../../../components/Select/Select";
import FreshNewColorPicker from "../../../../../components/freshNewColorPicker/freshNewColorPicker";
import { isValidJSONString } from "../../../../../utils/utils";
import { AddImageModal } from "../addImageModal/addImageModal";
import ImagesForm from "../../../../../components/forms/imagesForm/imagesForm";
import { deleteFile, streamFile, streamFileMutation } from "../../../../../requests/file";
import { useMutation } from "react-query";

interface ProductFormProps {
  trackId: string;
  product: IProduct;
  validationError: string[];
  onChange: (product: IProduct) => void;
  setValidationError: (validationError: string[]) => void;
  // handleValidateForm: (product: IProduct) => void;
}

export default function ProductForm({
  trackId,
  product,
  validationError,
  setValidationError,
  // handleValidateForm,
  onChange,
}: ProductFormProps) {

  const { mutate: addFile, isLoading } = useMutation(streamFileMutation, {
    onSuccess(data, variables, context) {
      let incomingPicturesList = [...product.incomingPicturesList || []];
      incomingPicturesList.push(data.message)
      onChange && onChange({...product, incomingPicturesList})
    },
    onError(error, variables, context) {},
  });


  const { mutate: deleteImage } = useMutation(deleteFile, {
    onSuccess(data, variables, context) {
      let incomingPicturesList = [...product.incomingPicturesList || []];
      if (data.message) {
        let fileIndex = incomingPicturesList.findIndex(image => image === data.filename);

        if (fileIndex >= 0) {
          incomingPicturesList.splice(fileIndex, 1);
          onChange && onChange({...product, incomingPicturesList})
        }
      }
    },
    onError(error, variables, context) {},
  });

  useEffect(() => {
    if (product && !product.productGroup) {
      handleChange({
        name: "operationGroup",
        value: FITLERED_OPERATION_GROUP[0],
      });
    }
  }, [product.productGroup]);

  const FITLERED_OPERATION_GROUP = useMemo(
    () => OPERATION_GROUP.filter((op) => op.includes(product.productGroup)),
    [product.productGroup]
  );

  const [fileList, setFileList] = useState<File[]>([]);

  const [commentObject, setCommentObject] = useState<ICommentObject>(
    initCommentObject()
  );

  useEffect(() => {
    if (JSON.stringify(commentObject) !== product.comments) {
      setCommentObject(initCommentObject())
    }
  }, [product])

  useEffect(() => {
    const newProduct = { ...product };
    newProduct.comments = JSON.stringify(commentObject);
    onChange(newProduct);
  }, [commentObject]);

  return (
    <form onSubmit={handleSubmit}>
      <label>Groupe d'articles</label>
      <Select
        optionsList={PRODUCT_GROUP}
        value={product.productGroup}
        setValue={(value) => handleChange({ name: "productGroup", value })}
        className={isNotValid("productGroup")}
        placeholder="Sélectionner un groupe d'article..."
      />
      <label>Groupe d'opérations</label>
      <Select
        optionsList={FITLERED_OPERATION_GROUP}
        value={product.operationGroup}
        className={isNotValid("operationGroup")}
        setValue={(value) => handleChange({ name: "operationGroup", value })}
        placeholder="Sélectionner un groupe d'opération..."
      />
      <FreshNewColorPicker
        color={product.color}
        handleColorChange={(value: string) =>
          onChange({ ...product, color: value })
        }
        className={[isNotValid("color"), style["color-picker"]].join(" ")}
      />
      <AutoCompleteInput
        name={"brand"}
        dataValue={product.brand}
        index={0}
        onChange={handleChange}
        fetchRequest={fetchBrands}
        className={isNotValid("brand")}
        handleStartCorrection={handleStartCorrection}
      />
      <AutoCompleteInput
        name={"category"}
        dataValue={product.category ?? ""}
        index={0}
        onChange={handleChange}
        fetchRequest={fetchCategories}
        className={isNotValid("category")}
        handleStartCorrection={handleStartCorrection}
      />
      <InputContainer>
        <label>Taille</label>
        <input
          type="number"
          name="size"
          onChange={({ target }) =>
            handleChange({ name: target.name, value: target.value })
          }
          value={product.size}
        />
      </InputContainer>
      <InputContainer>
        <label>Ticket</label>
        <input
          type="text"
          inputMode="numeric"
          name="workshopId"
          onChange={({ target }) => {
            handleChange({ name: target.name, value: target.value });
          }}
          className={isNotValid("workshopId")}
          value={product.workshopId}
          placeholder="1-1-XXX"
          onFocus={AddTicketPrefix}
        />
      </InputContainer>

      <ImagesForm
          title={`incoming-cart-to-logistic`}
          imageList={product.incomingPicturesList || []}
          // fileList={fileList}
          // setFileList={(fileList: File[], isWebCam) =>
          //   handleFileList(fileList, isWebCam)
          // }
          className={style["images-form-container"]}
          deleteImage={(uri, index) => handleDeleteImageByName(uri)}
          setIsLoading={(v) => {}}
          onAddFile={handleAddImage}
          onDeleteFile={handleDeleteImage}
        />

      <div className={style["product-list-bottom"]}>
        <InputContainer>
          <div className={style["check-line"]}>
            <input
              type="checkbox"
              name={"embauchoir"}
              className={style["input-checkbox"]}
              onChange={handleIsChecked}
              checked={isChecked("embauchoir")}
            />
            <span>Embauchoirs</span>
            <input
              name={"embauchoir"}
              disabled={!isChecked("embauchoir")}
              type="text"
              className={style["input-text"]}
              onChange={handleCommentChange}
              value={commentObject.embauchoir ?? ""}
            />
          </div>
        </InputContainer>
        <InputContainer>
          <div className={style["check-line"]}>
            <input
              type="checkbox"
              name={"pochon"}
              className={style["input-checkbox"]}
              onChange={handleIsChecked}
              checked={isChecked("pochon")}
            />
            <span>Pochons</span>
            <input
              type="text"
              name={"pochon"}
              className={style["input-text"]}
              disabled={!isChecked("pochon")}
              onChange={handleCommentChange}
              value={commentObject.pochon ?? ""}
            />
          </div>
        </InputContainer>
        <InputContainer>
          <div className={style["check-line"]}>
            <input
              type="checkbox"
              name={"other"}
              className={style["input-checkbox"]}
              onChange={handleIsChecked}
              checked={isChecked("other")}
            />
            <span>Autre</span>
            <input
              type="text"
              name={"other"}
              className={style["input-text"]}
              disabled={!isChecked("other")}
              value={commentObject.other ?? ""}
              onChange={handleCommentChange}
            />
          </div>
        </InputContainer>
      </div>

      
      
      {/* <div className={style["widgets-container-bottom"]}>
        <button
          className={style["validate-product"]}
          onClick={() => handleValidateForm(product)}
        >
          Suivant
        </button>
      </div> */}
    </form>
  );

  // async function submitFile(slug: string, file: File) {

  //   try {
  //     const response = await streamFile(slug, file);
  //   }

  //   try {
  //     for (const [index, logisticFile] of fileList.entries()) {
  //       if (logisticFile) {
  //         const newIncomingPicturesList = [];
  //         for (let file of logisticFile) {
  //           newIncomingPicturesList.push(response.message);
  //         }
  //         const newFormValues = { ...logistic };
  //         if (newFormValues.productsList[index]) {
  //           if (
  //             newFormValues.productsList[index].incomingPicturesList ===
  //             undefined
  //           ) {
  //             Object.assign(newFormValues.productsList[index], {
  //               incomingPicturesList: newIncomingPicturesList,
  //             });
  //           } else {
  //             newFormValues.productsList[index].incomingPicturesList =
  //               newFormValues.productsList[index].incomingPicturesList?.concat(
  //                 newIncomingPicturesList
  //               );
  //           }
  //         }
  //         setLogistic(newFormValues);
  //       }
  //     }
  //   } catch (error) {
  //     toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
  //   }
  // }


  function handleFileList(_fileList: File[], isWebCam: boolean) {
    if (fileList) {
      let newFileList = [...fileList];
      if (isWebCam) {
        _fileList.forEach((file: File) => {
          newFileList.push(file);
        });
      } else {
        newFileList = _fileList;
      }
      setFileList(newFileList);
    }
  }

  async function handleAddImage(file: File) {
    addFile({filename: `${trackId}-logistic.`, fileContent: file})
  }

  async function handleDeleteImageByName(filename: string) {
    deleteImage(filename)
    // onChange && onChange(product);
    // if (
    //   product &&
    //   window.confirm("Voulez-vous supprimer l'image definitivement")
    // ) {
    //   if (
    //     product.incomingPicturesList
    //   ) {
    //     product.incomingPicturesList?.splice(index, 1);
    //   }
    //   onChange && onChange(product);
    //   await deleteFileFromLogistic(imageURI);
    // }
  }

  async function handleDeleteImage(file: File) {
    deleteImage(file.name)
    // onChange && onChange(product);
    // if (
    //   product &&
    //   window.confirm("Voulez-vous supprimer l'image definitivement")
    // ) {
    //   if (
    //     product.incomingPicturesList
    //   ) {
    //     product.incomingPicturesList?.splice(index, 1);
    //   }
    //   onChange && onChange(product);
    //   await deleteFileFromLogistic(imageURI);
    // }
  }

  function initCommentObject() {
    if (!isValidJSONString(product.comments)) {
      return {};
    }
    let initialCommentObject: ICommentObject = {};
    initialCommentObject = JSON.parse(product.comments);
    return initialCommentObject;
  }

  function handleCommentChange({ target }: { target: HTMLInputElement }) {
    const newCommentObject = { ...commentObject };

    newCommentObject[target.name as keyof ICommentObject] = target.value;
    setCommentObject(newCommentObject);
  }

  function handleIsChecked({ target }: { target: HTMLInputElement }) {
    const newCommentObject = { ...commentObject };

    if (target.checked) {
      Object.assign(newCommentObject, {
        [target.name]:
          newCommentObject[target.name as keyof ICommentObject] ?? "",
      });
    } else {
      delete newCommentObject[target.name as keyof ICommentObject];
    }
    setCommentObject(newCommentObject);
  }
  function isChecked(name: string): boolean {
    return Object.entries(commentObject).some(
      (value: [string, any]) => value[0] === name
    );
  }

  function handleChange({
    name,
    value,
  }: {
    name: string;
    value: string | number;
  }) {
    let newValues = { ...product, [name]: value };
    onChange(newValues);
  }

  function isNotValid(name: string): string {
    if (validationError.find((value: string) => value === name)) {
      return style["validation-error"];
    }
    return "";
  }

  function handleStartCorrection(name: string) {
    const newValidation = validationError.filter(
      (value: string) => value !== name
    );
    setValidationError([...newValidation]);
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
  }

  function AddTicketPrefix({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    if (!target.value.startsWith("1-1-") && !target.value) {
      handleChange({ name: target.name, value: "1-1-" + target.value });
    }
    handleStartCorrection("workshopId");
  }
}
