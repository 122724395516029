import style from "./productModal.module.scss";
import ModalDetailedProduct from "../logisticModal/logisticReadSection/ModalDetailedProduct/ModalDetailedProduct";
import { IProduct } from "../../../types/logistic.types";
import ContactInfoCard from "../../contactInfoCard/contactInfoCard";

interface ProductModalProps {
  product: IProduct;
}

export default function ProductModal({ product }: ProductModalProps) {
  return (
    <div className={style["modal-detailed-product-container"]}>
      <ContactInfoCard
        contact={product?.contact}
        className={style["logistic-modal-contact-card"]}
        trackId={product?.trackId}
      />
      <ModalDetailedProduct product={product} />
    </div>
  );
}
