import { IShippingMethod } from "../types/accounting.types";

export const DEFAULT_BUSINESS_HOURS = [
  {
    start: "08:00",
    end: "11:00",
  },
  {
    start: "18:00",
    end: "21:00",
  },
];

export const PARIS_ZIPCODES = [
  "92130",
  "97132",
  "92190",
  "92360",
  "92310",
  "92312",
  "92210",
  "92100",
  "92150",
  "92800",
  "92500",
  "92014",
  "92200",
  "92400",
  "92300",
  "92110",
  "92600",
  "92270",
  "92700",
  "92036",
  "92390",
  "92035",
  "94300",
  "94410",
  "94160",
  "94130",
  "75048",
  "94340",
  "94130",
  "94033",
  "94220",
  "93100",
  "93170",
  "94800",
  "92170",
  "92240",
  "92120",
  "94270",
  "94250",
  "94205",
  "92320",
  "94230",
  "93460",
  "94110",
  "93400",
  "93200",
  "93206",
  "93210",
  "93284",
  "93300",
  "93500",
  "93022",
  "93310",
  "93260",
  "75000",
  "75001",
  "75002",
  "75003",
  "75004",
  "75005",
  "75006",
  "75007",
  "75008",
  "75009",
  "75010",
  "75011",
  "75012",
  "75013",
  "75014",
  "75015",
  "75016",
  "75017",
  "75018",
  "75019",
  "75020",
  "75116",
  "75270",
  "75680",
];

export const SHIPPING_METHOD_DEFAULT: IShippingMethod = {
  name: "",
  shippingService: "INTERNAL",
  price: 0,
  priceNoTax: 0,
  freeShippingMinimumAmount: 0,
  businessDays: {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  },
  businessHours: [],
  internationalFees: 0
};

export const SHIPPING_METHOD_CHRONOPOST_2SHOP: IShippingMethod = {
  name: "En Point Relais",
  shippingService: "CHRONOPOST-2SHOP",
  price: 15,
  priceNoTax: 12.5,
  freeShippingMinimumAmount: 150,
  businessDays: {
    sunday: false,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
  },
  businessHours: [
    {
      start: "08:00",
      end: "19:00",
    },
  ],
  zipCodeLimit: [],
  internationalFees: 0
};

export const SHIPPING_METHOD_CHRONOPOST_EUR: IShippingMethod = {
  name: "Europe",
  shippingService: "CHRONOPOST-EUR",
  price: 15,
  priceNoTax: 12.5,
  freeShippingMinimumAmount: 150,
  businessDays: {
    sunday: false,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
  },
  businessHours: [
    {
      start: "08:00",
      end: "19:00",
    },
  ],
  zipCodeLimit: [],
  internationalFees: 0
};

export const SHIPPING_METHOD_CHRONOPOST_PRO: IShippingMethod = {
  name: "Chronopost PRO",
  shippingService: "CHRONOPOST-PRO",
  price: 25,
  priceNoTax: 20.83,
  freeShippingMinimumAmount: 0,
  businessDays: {
    sunday: false,
    monday: true,
    tuesday: false,
    wednesday: false,
    friday: false,
    saturday: false,
    thursday: false,
  },
  businessHours: [
    {
      start: "08:00",
      end: "20:00",
    },
  ],
  zipCodeLimit: [],
  internationalFees: 0
};

export const SHIPPING_METHOD_INTERNAL = {
  name: "À votre adresse",
  shippingService: "INTERNAL",
  price: 15,
  priceNoTax: 12.5,
  freeShippingMinimumAmount: 150,
  internationalFees: 0,
  businessDays: {
    sunday: false,
    monday: true,
    tuesday: true,
    wednesday: true,
    friday: true,
    saturday: false,
    thursday: true,
  },
  businessHours: [
    {
      start: "08:00",
      end: "11:00",
    },
    {
      start: "18:00",
      end: "21:00",
    },
  ],
  zipCodeLimit: [
    "92130",
    "92100",
    "92200",
    "92300",
    "92110",
    "94300",
    "94160",
    "94220",
    "93100",
    "93170",
    "92170",
    "92240",
    "92120",
    "94270",
    "94250",
    "93400",
    "93210",
    "93300",
    "93500",
    "93310",
    "93260",
    "75000",
    "75001",
    "75002",
    "75003",
    "75004",
    "75005",
    "75006",
    "75007",
    "75008",
    "75009",
    "75010",
    "75011",
    "75012",
    "75013",
    "75014",
    "75015",
    "75016",
    "75017",
    "75018",
    "75019",
    "75020",
    "75116",
    "75270",
    "75680",
  ],
  isPublic: true,
};
