import { useEffect } from "react";
import { queryObject } from "../types/query.types";

export default async function useSaveQueryStorage(
  query: queryObject,
  name: string
) {
  useEffect(() => {
    localStorage.setItem(`gp-management.${name}`, JSON.stringify(query));
  }, [query]);
}

export function loadQueryStorage(initialQuery: queryObject, name: string) {
  const query = localStorage.getItem(`gp-management.${name}`);
  if (query && query !== "undefined") {
    return JSON.parse(query);
  }
  return initialQuery;
}
