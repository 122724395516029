import style from "./proIncomingShipping.module.scss";
import { useQuery } from "react-query";
import { getCartsList } from "../../../../requests/professionalCarts";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { IProCart, IProduct } from "../../../../types/proCart.types";
import { useModalComponent } from "../../../../context/modalComponent.context";
import { useEffect, useMemo, useRef, useState } from "react";
import ProShippingModal from "../../../../components/modals/proShippingModal/proShippingModal";
import { getShop } from "../../../../requests/shops";
import { queryObject } from "../../../../types/query.types";
import { Edit } from "@mui/icons-material";
import SearchByWorkshopId from "../../../../components/searchByWorkshopId/searchByWorkshopId";
import LoadingSpinner from "../../../../components/loadingSpinner/loadingSpinner";
import {
  ILogistic,
  IProduct as ILogisticProduct,
} from "../../../../types/logistic.types";
import { toast } from "react-toastify";
import { streamFile } from "../../../../requests/file";
import { generateLogisticFromCartPro } from "../../../../requests/trackerPro";
import { TOAST_ERROR_OPTIONS } from "../../../../utils/toast.options";
import {
  finishShippingPro,
  getShippingPro,
} from "../../../../requests/shippingPro";
import ValidateButton from "../../../../components/validateButton/validateButton";
import CartProCardProduct from "../cartProCartProduct/cartProCartProduct";
import ShowHistory from "../showHistory/ShowHistory";
import AddNewCartProModal from "../../../../components/modals/addNewCartProModal/AddNewCartProModal";

export const INITIAL_QUERY: queryObject = {
  field: "",
  value: "",
  service: "",
  direction: "",
  status: "",
  limit: 10,
  offset: 0,
  sort: "",
  dateFrom: "",
  dateTo: "",
  state: "CART_VALIDATED",
  workshopId: "",
};

interface IProductShipping extends IProduct {
  canceled: boolean;
}

interface IProCartShipping extends IProCart {
  productsList: IProductShipping[];
}

export default function ProIncomingShipping() {
  const { shopId } = useParams();
  const [searchParams] = useSearchParams();
  const SHOP_NAME = searchParams.get("shopName");
  const SHIPPING_ID = searchParams.get("shipping-id") as string | undefined;

  const [query, setQuery] = useState<Partial<queryObject>>(INITIAL_QUERY);
  const { data, isFetching, refetch } = useQuery(
    ["cart-list", query],
    () => getCartsList(formatedQuery()),
    {
      enabled: Boolean(shopId),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
  const { data: dataShop } = useQuery("shop", () => getShop(shopId), {
    enabled: Boolean(shopId),
  });
  const { data: dataShipping, refetch: refetchShippingPro } = useQuery(
    ["shipping-pro", SHIPPING_ID],
    () => getShippingPro(SHIPPING_ID)
  );
  const [cartList, setCartList] = useState<IProCartShipping[]>(
    formatCartList(data?.cartsList || [])
  );
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const { setModalIsOpen, setModalChildren, setConfirmClose } =
    useModalComponent();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setCartList(formatCartList(data.cartsList));
    }
  }, [data]);

  if (isFetching) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["pro-incoming-shipping"]}>
      <div className={style["header"]}>
        <div className={style["header-left"]}>
          <div className={style["title"]}>{SHOP_NAME}</div>
          <div>{"Liste des paniers en attente dans la boutique"}</div>
        </div>
        <div>
          <SearchByWorkshopId
            workshopId={query.workshopId || ""}
            setWorkshopId={handleSearchByWorkshopId}
          />
        </div>
      </div>
      <div>
        <div className={style["footer"]}>
          <ValidateButton
            handleOnClick={handleAddNewCart}
            title={"Ajouter un nouveau panier"}
          />
          <div className={style["footer-right"]}>
            <div>
              {`Paniers réceptionnés ${dataShipping?.trackId?.length} / ${
                (dataShipping?.trackId?.length ?? 0) +
                (data?.cartsList.length ?? 0)
              }`}
            </div>
            <ValidateButton
              className={style["green-button"]}
              handleOnClick={handleFinishShipping}
              title={"Finir la collecte"}
            />
          </div>
        </div>
      </div>
      <div className={style["cart-list"]}>
        <>
          {cartList.length > 0 ? (
            cartList.map((cart: IProCartShipping) => {
              return (
                <CartProCard
                  cart={cart}
                  setCart={(cart) => setCart(cart, cart.id)}
                  key={cart.id}
                  refresh={refresh}
                  shippingProId={
                    SHIPPING_ID || dataShop?.nextIncomingShipping?.documentId
                  }
                  shopName={SHOP_NAME || dataShop?.name || "NR"}
                  nextOutgoingShippingDate={
                    dataShop?.nextOutgoingShipping?.date
                  }
                />
              );
            })
          ) : (
            <div className={style["loading-container"]}>
              aucun panier disponible
            </div>
          )}
        </>
        {isFetching && (
          <div className={style["loading-container"]}>
            <LoadingSpinner color="gold" />
          </div>
        )}
      </div>
      <div
        className={style["shipping-pro-history"]}
        onClick={() => setShowHistory(!showHistory)}
      >
        <span className={style["shipping-pro-history-message"]}>
          {showHistory
            ? "Fermer"
            : `Afficher les paniers déjà réceptionner (${dataShipping?.trackId?.length})`}
        </span>
      </div>
      {showHistory && dataShipping && (
        <ShowHistory trackIdList={dataShipping?.trackId || []} />
      )}
    </div>
  );

  function refresh() {
    refetch();
    refetchShippingPro();
  }

  function postAddNewCart() {
    setModalIsOpen(false);
    refresh();
  }

  function handleAddNewCart() {
    if (!shopId) {
      return;
    }
    setModalChildren(
      <AddNewCartProModal shopId={shopId} postSubmit={postAddNewCart} />
    );
    setModalIsOpen(true);
    setConfirmClose(true);
  }

  async function handleFinishShipping() {
    if (!dataShipping || !dataShipping.id || !dataShipping.shopId) {
      toast.error("ShippingPro ID manquant", TOAST_ERROR_OPTIONS);
      return;
    }
    if (!window.confirm("Voulez-vous vraiment finir la collecte ?")) {
      return;
    }
    try {
      await finishShippingPro(
        dataShipping.id,
        dataShipping.direction,
        dataShipping.shopId,
        "DELIVERED"
      );
    } catch (error) {
      toast.error(
        "Un problème est survenu lors de la fin de la collecte",
        TOAST_ERROR_OPTIONS
      );
      console.error(error);
    } finally {
      navigate("/quick-access/pro-shipping/");
    }
  }

  function setCart(newCart: IProCartShipping, id?: string) {
    if (!id) {
      return;
    }

    setCartList(
      cartList.map((cart: IProCartShipping) => {
        return cart.id === id ? newCart : cart;
      })
    );
  }

  function formatedQuery(): string {
    let formatedQuery: string = `?shopId=${shopId}&state=CART_PENDING`;

    if (query.workshopId) {
      formatedQuery += `&productsList.workshopId=${query.workshopId}`;
    }
    formatedQuery += `&sort_field=productsList.workshopId&sort_direction=asc`;
    return formatedQuery;
  }

  function handleSearchByWorkshopId(value: string) {
    setQuery((prev) => ({ ...prev, workshopId: value }));
  }
  function resetQuery() {
    setQuery(INITIAL_QUERY);
  }

  function formatCartList(cartList: IProCart[]): IProCartShipping[] {
    return cartList.map((cart: IProCart) => {
      return {
        ...cart,
        productsList: cart.productsList.map((product) => {
          return { ...product, canceled: false };
        }),
      };
    });
  }
}

interface CartProCardProps {
  cart: IProCartShipping;
  setCart: (cart: IProCartShipping) => void;
  refresh: () => void;
  shopName: string;
  nextOutgoingShippingDate?: string;
  shippingProId?: string;
}

function CartProCard({
  cart,
  setCart,
  refresh,
  nextOutgoingShippingDate,
  shopName,
  shippingProId,
}: CartProCardProps) {
  const { setModalIsOpen, setModalChildren } = useModalComponent();
  const [fileList, setFileList] = useState<File[][]>([
    ...Array(cart.productsList.length).fill([]),
  ]);
  const [productCheckList, setProductCheckList] = useState<boolean[]>([
    ...Array(cart.productsList.length).fill(false),
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const READY_TO_CHECKIN = useMemo(
    () => productCheckList.every((item: boolean) => item === true),
    [productCheckList]
  );

  useEffect(() => {
    setProductCheckList([...Array(cart.productsList.length).fill(false)]);
  }, [cart.productsList]);

  useEffect(() => {
    setFileList([...Array(cart.productsList.length).fill([])]);
  }, [cart.productsList]);

  return (
    <div className={style["cart-pro-product-card"]} ref={ref}>
      <div className={`${style["cart-pro-product-card-container"]}`}>
        <div className={style["cart-pro-product-card-left"]}>
          <div>
            {READY_TO_CHECKIN ? (
              <div className={style["button-container"]}>
                {READY_TO_CHECKIN && (
                  <button
                    className={style["validate-button"]}
                    onClick={generateCartToLogistic}
                  >
                    Receptionner panier
                  </button>
                )}
              </div>
            ) : (
              <>
                <div className={style["product-card-displayname"]}>
                  {cart.contact.displayname || "-"}
                </div>
                {cart.customRef && (
                  <div className={style["product-card-custom-ref"]}>
                    {cart.customRef}
                  </div>
                )}
                <div className={style["product-card-information"]}>
                  Nombres d'articles {cart.productsList.length}
                </div>
                {cart.comments && (
                  <div className={style["product-card-comment-container"]}>
                    <span>Commentaire</span>
                    <span className={style["product-card-comment"]}>
                      {cart.comments}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className={style["edit-cart"]} onClick={handleOpenEditModal}>
            modifier panier
            <div className={style["edit-icon"]}>
              <Edit />
            </div>
          </div>
        </div>
        <div className={style["cart-pro-product-card-right"]}>
          <div className={style["product-list"]}>
            {cart.productsList.map(
              (product: IProductShipping, index: number) => {
                return (
                  <CartProCardProduct
                    cart={cart}
                    fileList={fileList[index]}
                    setFileList={(fileList) =>
                      setProductFileList(fileList, index)
                    }
                    index={index}
                    isChecked={productCheckList[index]}
                    key={index}
                    setIsChecked={() => setIsChecked(index)}
                    product={product}
                  />
                );
              }
            )}
          </div>
        </div>
      </div>
      {isLoading && (
        <div className={style["loading-spinner-container"]}>
          <div
            className={style["loading-spinner"]}
            style={{
              height: (ref.current?.clientHeight || 0) - 20,
              width: ref.current?.clientWidth,
            }}
          >
            <LoadingSpinner color="gold" />
          </div>
        </div>
      )}
    </div>
  );

  function proCartToLogistic(): {
    logistic: ILogistic;
    toUpdateCart: IProCart;
    toAddCart?: IProCart;
  } {
    const cartWithFormatedOperations = {
      ...cart,
    };
    const toUpdateCart = {
      ...cartWithFormatedOperations,
      productsList: cartWithFormatedOperations.productsList.filter(
        (product: IProductShipping) => !product.canceled
      ),
    };
    const toAddCartProductsList =
      cartWithFormatedOperations.productsList.filter(
        (product: IProductShipping) => product.canceled
      );
    let toAddCart: IProCart | undefined;
    if (toAddCartProductsList.length !== 0) {
      toAddCart = {
        ...cartWithFormatedOperations,
        productsList: toAddCartProductsList,
      };
      delete toAddCart.id;
      delete toAddCart.trackId;
    }

    const productsList: ILogisticProduct[] = [];
    cart.productsList.forEach((product: IProductShipping) => {
      if (!product.canceled) {
        productsList.push({
          dueDate: nextOutgoingShippingDate
            ? new Date(nextOutgoingShippingDate)
            : new Date(),
          operationsList: product.operationsList,
          productGroup: product.productGroup,
          operationGroup: product.operationGroup,
          workshopId: product.workshopId,
          status: "WORKSHOP_REPAIR",
          brand: product.brand || "",
          color: product.color || "",
          comments: product.comments || "",
        });
      }
    });

    const logistic: Partial<ILogistic> = {
      trackId: cart.trackId,
      contact: {
        ...cart.contact,
        organization: cart.contact.organization || shopName,
      },
      comments: cart.comments,
      status: "LOGISTIC_WORKSHOP",
      productsList: productsList,
    };

    return { logistic, toUpdateCart, toAddCart } as {
      logistic: ILogistic;
      toUpdateCart: IProCart;
      toAddCart?: IProCart;
    };
  }

  async function generateCartToLogistic() {
    if (!cart.trackId) {
      toast.error("TrackId manquant.", TOAST_ERROR_OPTIONS);
      return;
    }
    if (!shippingProId) {
      toast.error("ShippingProId manquant.", TOAST_ERROR_OPTIONS);
      return;
    }
    setIsLoading(true);
    const { logistic, toUpdateCart, toAddCart } = proCartToLogistic();
    try {
      for (const [index, files] of fileList.entries()) {
        let fileNameList: string[] = [];
        for (const fileObj of files) {
          const response = await streamFile(
            `-${cart.trackId}-pro-incoming-shipping.`,
            fileObj
          );
          fileNameList = [...fileNameList, response.message];
        }
        logistic.productsList = logistic.productsList.map(
          (product: ILogisticProduct, prodIndex: number) => {
            if (prodIndex === index) {
              return { ...product, incomingPicturesList: fileNameList };
            }
            return product;
          }
        );
        toUpdateCart.productsList = toUpdateCart.productsList.map(
          (product: IProduct, i: number) => {
            if (i === index) {
              return {
                ...product,
                state: "WORKSHOP_REPAIR",
                pictureList: fileNameList,
              };
            }
            return product;
          }
        );
        fileNameList.slice(0, 0);
      }
      const response = await generateLogisticFromCartPro({
        trackId: cart.trackId,
        logistic,
        shippingProId,
        toUpdateCart,
        toAddCart,
      });
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    } finally {
      setIsLoading(false);
      refresh();
    }
  }

  function setProductFileList(newFileList: File[], index: number) {
    setFileList(
      fileList.map((file: File[], i) => {
        return index === i ? newFileList : file;
      })
    );
  }

  function setIsChecked(index: number) {
    setProductCheckList(
      productCheckList.map((checked: boolean, i: number) => {
        return i === index ? !checked : checked;
      })
    );
  }

  function handleOpenEditModal() {
    setModalChildren(<ProShippingModal cart={cart} setCart={setCart} />);
    setModalIsOpen(true);
  }
}
