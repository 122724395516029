import { fetchShipping } from "../../../requests/shipping";
import { useQuery } from "react-query";
import Create from "../create/Create";
import { useLoaderData, useParams } from "react-router-dom";
import useLogout from "../../../hooks/useLogout";
import { loadShipping } from "../../../requests/tracker";
import { IShipping } from "../../../types/shipping.types";
import { authloader } from "../..";
import { GP_SHIPPING_ADDRESS } from "../../../utils/shipping.init";

export async function loader({ params }: { params: any }) {
  try {
    const { shipping } = await loadShipping({ params });
    authloader();
    return shipping;
  } catch (error: any) {
    throw error;
  }
}

export default function Generate() {
  const data: IShipping | null = useLoaderData() as IShipping;
  if (!data.period) {
    data.period = { start: new Date(), end: new Date() };
  }
  if (data.direction === "INCOMING" && !data.recipient) {
    data.recipient = GP_SHIPPING_ADDRESS;
  } else if (data.direction === "OUTGOING" && !data.sender) {
    data.sender = GP_SHIPPING_ADDRESS;
  }
  return <>{data && <Create shipping={data} />}</>;
}

// export default function Edit() {
//   const { id } = useParams();
//   const { data, isFetching, error, refetch } = useQuery(
//     "shippingDocument",
//     () =>
//       fetchShipping(
//         new Request(`${process.env.REACT_APP_API_URL}/shipping/${id}`),
//         { id: id }
//       ),
//     { refetchOnWindowFocus: false }
//   );

//   useLogout(data, isFetching);

//   return (
//     <>
//       {data && !isFetching ? <Create refetch={refetch} data={data} /> : <></>}
//     </>
//   );
// }
