import { IOperation } from "../types/accounting.types";

const OPERATION_ACRONYM_TABLE_COLOR = new Map([
  ["Patins", "Pa"],
  ["Talons", "Ta"],
  ["Fers", "Fe"],
  ["Ressemelage", "RC"],
  ["Ressemelage caoutchouc", "RCaout"],
  ["Demi-semelle", "DemiC"],
  ["Demi-semelle caoutchouc", "DemiCaout"],
  ["Glissoir", "Gli"],
  ["Envelloppe", "Env"],
  ["Collage", "Coll"],
  ["Couture", "Cou"],
]);

const OPERATION_ACRONYM_TABLE = new Map([
  ["Patins", "Pa"],
  ["Talons", "Ta"],
  ["Fers", "Fe"],
  ["Ressemelage", "RC"],
  ["Ressemelage caoutchouc", "RCaout"],
  ["Demi-semelle", "DemiC"],
  ["Demi-semelle caoutchouc", "DemiCaout"],
  ["Glissoir", "Gli"],
  ["Envelloppe", "Env"],
  ["Collage", "Coll"],
  ["Couture", "Cou"],
  ["Soin", "Soin"],
  ["Teinture", "Tein"],
  ["FAG", "FAG"],
  ["Tranche", "Tr"],
  ["Doublure", "Do"],
  ["Jonc", "Jo"],
  ["Enchape", "En"],
  ["Enchappe", "En"],
  ["Bandouliere", "Ban"],
  ["Bandoulière", "Ban"],
  ["Poignee", "Poi"],
  ["Poignées", "Poi"],
]);

const OPERATION_ACRONYM_TABLE_BY_OPERTION_TYPE = new Map([
  ["Patins", "Pa"],
  ["Talons", "Ta"],
  ["Fers", "Fe"],
  ["Ressemelage", "RC"],
  ["Ressemelage caoutchouc", "RCaout"],
  ["Demi-semelle", "DemiC"],
  ["Demi-semelle caoutchouc", "DemiCaout"],
  ["Glissoir", "Gli"],
  ["Envelloppe", "Env"],
  ["Collage", "Coll"],
  ["Couture", "Cou"],
  ["Soin", "Soin"],
  ["Teinture", "Tein"],
  ["FAG", "FAG"],
  ["Tranche", "Tr"],
  ["Doublure", "Do"],
  ["Jonc", "Jo"],
  ["Enchape", "En"],
  ["Enchappe", "En"],
  ["Bandouliere", "Ban"],
  ["Bandoulière", "Ban"],
  ["Poignee", "Poi"],
  ["Poignées", "Poi"]
]);

const SHORT_OPERATIONS_LIST = [
  "Patins",
  "Talons",
  "Fers",
  "Ressemelage",
  "Ressemelage caoutchouc",
  "Demi-semelle",
  "Demi-semelle caoutchouc",
  "Glissoir",
  "Envelloppe",
  "Collage",
  "Couture",
  "Soin",
  "Teinture",
  "FAG",
  "Tranche",
  "Doublure",
  "Jonc",
  "Enchape",
  "Enchappe",
  "Bandouliere",
  "Bandoulière",
  "Poignee",
  "Poignées"
];

export function getOperationsListAcronym(operationsList: IOperation[]) {
  const sanitizedOperationsList = operationsList.filter((op) => !op.canceled);
  let operationsAcronymList = sanitizedOperationsList.map((op) => {
    const shortOperation = SHORT_OPERATIONS_LIST.find((short) =>
      op.description.toLocaleLowerCase("fr").includes(short.toLocaleLowerCase("fr"))
    );
    if (shortOperation && OPERATION_ACRONYM_TABLE.get(shortOperation)) {
      return OPERATION_ACRONYM_TABLE.get(shortOperation);
    }
  });
  operationsAcronymList = operationsAcronymList.filter((op) => op);
  if (operationsAcronymList.length < sanitizedOperationsList.length) {
    operationsAcronymList.push(
      `+${sanitizedOperationsList.length - operationsAcronymList.length}`
    );
  }

  return operationsAcronymList;
}
