import { useQuery } from "react-query";
import style from "./logisticModal.module.scss";
import { fetchLogistic } from "../../../requests/logistic";
import { useEffect, useState } from "react";
import ContactInfoCard from "../../contactInfoCard/contactInfoCard";
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";
import LogisticReadSection from "./logisticReadSection/logisticReadSection";
import LogisticActionSection from "./logisticActionSection/logisticActionSection";
import LogisticModalHeader from "./logisticModalHeader/LogisticModalHeader";

interface LogisticModalProps {
  dueDate?: Date;
  id: string;
  requiredActions: boolean;
  productIndex?: number;
}

export default function LogisticModal({
  dueDate,
  id,
  requiredActions,
  productIndex,
}: LogisticModalProps) {
  const { data, isFetching } = useQuery(
    "logistic-modal",
    () => fetchLogistic({ params: { id: id } }),
    { refetchOnWindowFocus: false }
  );
  const [tabSelected, setTabSelected] = useState<"read" | "action">(
    requiredActions ? "action" : "read"
  );

  if (isFetching) {
    return (
      <div className={style["logistic-modal"]}>
        <LoadingSpinner color="gold" />
      </div>
    );
  }

  return (
    <div className={style["logistic-modal"]}>
      <div className={style["logistic-modal-container"]}>
        <ContactInfoCard
          contact={data?.contact}
          className={style["logistic-modal-contact-card"]}
          trackId={data?.trackId}
        />
        <LogisticModalHeader
          data={data}
          requiredActions={requiredActions}
          tabSelected={tabSelected}
          setTabSelected={setTabSelected}
        />
        <LogisticTabDispatch />
      </div>
    </div>
  );

  function LogisticTabDispatch() {
    if (!data) {
      return <LoadingSpinner color="gold" />;
    }
    switch (tabSelected) {
      case "read":
        return <LogisticReadSection productIndex={productIndex} data={data} />;
      case "action":
        return <LogisticActionSection data={data} dueDate={dueDate} />;
      default:
        return <LogisticReadSection productIndex={productIndex} data={data} />;
    }
  }
}
