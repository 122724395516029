import { useContext, useState } from "react";
import {
  cancelShippingLabel,
  fetchShipping,
  reserveShippingEsdLabel,
  reserveShippingLabel,
  updateShippingState,
} from "../../../requests/shipping";
import { useQuery } from "react-query";
import { useModalComponent } from "../../../context/modalComponent.context";
import style from "./shippingReadModal.module.scss";
import translate from "../../../translation";
import { IProduct } from "../../../types/logistic.types";
import SendIcon from "@mui/icons-material/Send";
import {
  CancelScheduleSend,
  Done,
  Download,
  Edit,
  Launch,
  LocationOn,
  ModeEdit,
  Phone,
} from "@mui/icons-material";
import { handleGetShippingLabel } from "../../forms/shippingForm/externalShipping/externalShipping";
import { handleImageError } from "../../../utils/utils";
import { Link } from "react-router-dom";
import { PRINTMODE, STATE_OPTIONS } from "../../../utils/shipping.init";
import { stateColor } from "../../../routes/shipping/calendar/shippingModal/shippingModal";
import logo from "../../../assets/logo-chronopost.png";
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";
import { StatusItem } from "../../statusItem/statusItem";
import ContactInfoCard from "../../contactInfoCard/contactInfoCard";
import { fetchLogisticFromTrackerId } from "../../../requests/tracker";
import { ProtectedComponent } from "../../protectedComponent/protectedComponent";
import ShippingProductState from "../../../routes/shipping/calendar/shippingModal/shippingProductState";
import { TOAST_ERROR_OPTIONS } from "../../../utils/toast.options";
import { toast } from "react-toastify";
import { IShipping } from "../../../types/shipping.types";

interface ShippingReadModalProps {
  id: string;
}

export default function ShippingReadModal({ id }: ShippingReadModalProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editState, setEditState] = useState<boolean>(false);
  const { data, error, isFetching, refetch } = useQuery(
    "shipping-modal",
    () => fetchShipping({ id: id }),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [state, setState] = useState<string>(data?.state || "PENDING");
  const {
    isFetching: logisticIsFetching,
    error: logisticError,
    data: logisticData,
  } = useQuery(
    ["logistic-from-trackerId", data?.trackId],
    () => fetchLogisticFromTrackerId({ params: { id: data?.trackId } }),
    {
      refetchOnWindowFocus: false,
      enabled: data?.trackId !== undefined,
    }
  );
  const shippingData =
    data?.direction === "OUTGOING" ? data?.recipient : data?.sender;
  const { setModalIsOpen } = useModalComponent();

  return (
    <div className={style["shipping-modal"]}>
      {!isFetching ? (
        <>
          {data && data.contact && (
            <>
              <ContactInfoCard
                contact={data.contact}
                className={style["shipping-modal-contact-card"]}
                trackId={data?.trackId}
              />
              <div className={style["modal-top"]}>
                <div>
                  <div className={style["date"]}>
                    {data.period &&
                      new Date(data?.period.start).toLocaleDateString()}
                  </div>
                  <div className={style["time-range"]}>
                    {data.period &&
                      new Date(data?.period.start || "").toLocaleTimeString(
                        "fr-FR",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    {" - "}
                    {data.period &&
                      new Date(data.period.end || "").toLocaleTimeString(
                        "fr-FR",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                  </div>
                </div>

                <div className={style["state"]}>
                  <Link
                    onClick={handleEdit}
                    to={`/shipping/edit/${data.id}`}
                    className={style["tools-icon"]}
                  >
                    <Edit className={style["edit-icon"]} />
                  </Link>
                  <div className={style["state-container"]}>
                    <StatusItem
                      data={{ state: data.state, documentId: data.id }}
                    />
                  </div>
                </div>
              </div>
              <div className={style["nom-complet"]}>
                {data.contact && data.contact.givenname}{" "}
                {data.contact && data.contact.familyname}
                <Link
                  className={style["access-button"]}
                  to={`/overview/${data?.trackId}`}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setModalIsOpen(false);
                  }}
                >
                  <Launch className={style["access-button-icon"]} />
                </Link>
              </div>
              <div className={style["direction-container"]}>
                <div
                  className={`${
                    data?.direction === "OUTGOING"
                      ? style["outgoing"]
                      : style["incoming"]
                  } 
        ${style["direction"]}`}
                >
                  {data.direction && translate(data.direction)}
                </div>
                {(data.shippingMethod?.shippingService ===
                  "CHRONOPOST-2SHOP" || data.shippingMethod?.shippingService ===
                  "CHRONOPOST-EUR") && (
                  <img
                    alt="Chronopost"
                    className={style["external-shipping-logo"]}
                    src={logo}
                  />
                )}
              </div>
              <hr />
              <div className={style["address"]}>
                <div className={style["address-left"]}>
                  <div>
                    {shippingData?.address1} {shippingData?.address2}
                  </div>
                  <div>
                    {shippingData?.zipCode} {shippingData?.city}{" "}
                    {shippingData?.country}
                  </div>
                  <div className={style["address-comment"]}>
                    {shippingData?.comments}
                  </div>
                </div>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  target="_blank"
                  className={style["address-icon"]}
                  to={`http://maps.google.com/?q=${shippingData?.address1} ${shippingData?.address2} ${shippingData?.zipCode} ${shippingData?.city}`}
                >
                  <LocationOn />
                </Link>
              </div>
              <hr />
              <div className={style["address"]}>
                <div className={style["address-left"]}>
                  {shippingData?.phone}
                </div>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className={style["address-icon"]}
                  to={`tel:${data.contact.phone}`}
                >
                  <Phone />
                </Link>
              </div>
              <hr />
              {data.shippingMethod?.shippingService !== "INTERNAL" && (
                <ExternalShipping data={data} refetch={refetch} />
              )}
              <ProtectedComponent roles={["ROLE_ADMIN", "ROLE_LOGISTICS"]}>
                <div className={style["state-edit-container"]}>
                  {editState ? (
                    <>
                      <select
                        name="field"
                        className={style["select-state"]}
                        onChange={({ target }) => {
                          setState(target.value);
                        }}
                      >
                        <option>{"-- choisissez un état --"}</option>
                        {STATE_OPTIONS.map((value: string, index: number) => {
                          return (
                            <option key={index} value={value}>
                              {translate(value)}
                            </option>
                          );
                        })}
                      </select>
                      <div className={style["done-button-container"]}>
                        <div
                          className={style["done-button"]}
                          onClick={() => data.id && updateState(state, data.id)}
                        >
                          <Done />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={style["display-state"]}>
                        {`Status de la ${translate(
                          data?.direction || ""
                        ).toLocaleLowerCase()} : `}
                        <div
                          className={stateColor(data.state || "indisponible")}
                        >
                          {translate(data?.state || "")}
                        </div>
                      </div>
                      <div
                        className={style["edit-button"]}
                        onClick={changeEditState}
                      >
                        <ModeEdit />
                      </div>
                    </>
                  )}
                </div>
              </ProtectedComponent>
              <hr />
              {/* <div className={style["product-list"]}> */}
              {logisticData && (
                <div className={style["workshop-id-list"]}>
                  {logisticData.productsList &&
                    logisticData.productsList.length &&
                    logisticData.productsList.map(
                      (product: IProduct, index: number) => {
                        return (
                          <ShippingProductState
                            logisticId={logisticData.id}
                            product={product}
                            index={index}
                            key={index}
                          />
                        );
                      }
                    )}
                </div>
              )}
              {/* </div> */}
              {data.imagesList && data.imagesList.length > 0 && (
                <div className={style["image-list"]}>
                  {`Images :`}
                  {data?.imagesList.map((imageURI: string, key: number) => {
                    return (
                      <div key={key}>
                        <img
                          className={style["image-preview"]}
                          src={`${process.env.REACT_APP_API_URL}/file/${imageURI}`}
                          alt="previewShipping"
                          onError={handleImageError}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className={style["loading-container"]}>
          <LoadingSpinner color="gold" />
        </div>
      )}
    </div>
  );

  function handleEdit() {
    setModalIsOpen(false);
    window.scrollTo(0, 0);
  }

  function changeEditState() {
    setEditState(!editState);
  }

  async function updateState(state: string, id: string) {
    try {
      await updateShippingState(id, state);
      refetch();
      setEditState(!editState);
    } catch (error: any) {
      console.error(error);
    }
  }
}

interface ExternalShippingProps {
  data: IShipping;
  refetch: () => void;
}

function ExternalShipping({ data, refetch }: ExternalShippingProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const NEED_TO_GENERATE_LABEL =
    data?.externalShipping?.pickUpId &&
    !data?.externalShipping?.reservationNumber &&
    !data?.externalShipping?.externalId;
  const ACTION_REQUIRED =
    !data?.externalShipping?.pickUpId &&
    (data?.externalShipping?.reservationNumber ||
      data?.externalShipping?.externalId);
  const NEED_EDIT =
    !data?.externalShipping?.pickUpId &&
    !data?.externalShipping?.reservationNumber &&
    !data?.externalShipping?.externalId &&
    data?.externalShipping;

  const INCOMING_LABEL_AVAILABLE =
    data.externalShipping &&
    data.externalShipping.externalId &&
    data.externalShipping.reservationNumber;
  const INCOMING_LABEL_UNAVAILABLE =
    data.externalShipping &&
    (!data.externalShipping.externalId ||
      !data.externalShipping.reservationNumber);

  const OUTGOING_LABEL_AVAILABLE =
    data.externalShipping &&
    data.externalShipping.externalId &&
    data.externalShipping.reservationNumber &&
    data.externalShipping.pickUpId;
  const OUTGOING_LABEL_UNAVAILABLE =
    data.externalShipping &&
    data.externalShipping.pickUpId &&
    (!data.externalShipping.externalId ||
      !data.externalShipping.reservationNumber);
  const OUTGOING_ONLY_PICK_UP_LOCATION_MISSING =
    data.externalShipping &&
    !data.externalShipping.pickUpId &&
    data.externalShipping.reservationNumber &&
    data.externalShipping.externalId;
  const OUTGOING_EXTERNAL_SHIPPING_INFORMATION_MISSING =
    data.externalShipping &&
    !data.externalShipping.pickUpId &&
    !data.externalShipping.reservationNumber &&
    !data.externalShipping.externalId;

  const ERROR_EXTERNAL_SHIPPING_MISSING = !data.externalShipping;

  function needToGenerateLabel() {
    if (data.direction === "INCOMING") {
      if (ERROR_EXTERNAL_SHIPPING_MISSING) {
        return <ErrorIncomingExternalShipping />;
      }
      if (INCOMING_LABEL_AVAILABLE)
        return <DownloadShippingLabel data={data} refetch={refetch} />;
      if (INCOMING_LABEL_UNAVAILABLE) {
        return (
          <GenerateShippingLabel
            data={data}
            refetch={refetch}
            setIsLoading={setIsLoading}
          />
        );
      }
    }
    if (data.direction === "OUTGOING") {
      if (ERROR_EXTERNAL_SHIPPING_MISSING) {
        return <ErrorOutgoingExternalShipping />;
      }
      if (OUTGOING_LABEL_UNAVAILABLE) {
        return (
          <GenerateShippingLabel
            data={data}
            refetch={refetch}
            setIsLoading={setIsLoading}
          />
        );
      }
      if (OUTGOING_LABEL_AVAILABLE)
        return <DownloadShippingLabel data={data} refetch={refetch} />;
      if (OUTGOING_ONLY_PICK_UP_LOCATION_MISSING) {
        return (
          <ShippingLabelErrorNeedToCancelIt data={data} refetch={refetch} />
        );
      }
      if (OUTGOING_EXTERNAL_SHIPPING_INFORMATION_MISSING) {
        return <NeedToEdit data={data} />;
      }
    }
    return <></>;
  }

  const NEED_TO_GENERATE_INCOMING_SHIPPING_LABEL =
    data.direction === "INCOMING" && !data.externalShipping;

  return (
    <>
      <div>
        {isLoading ? (
          <div className={style["right"]}>
            <LoadingSpinner color="gold" />
          </div>
        ) : (
          <div className={style["right"]}>{needToGenerateLabel()}</div>
        )}
      </div>
      <hr />
    </>
  );
}

interface GenerateShippingLabelProps {
  data: IShipping;
  refetch: () => void;
  setIsLoading: (value: boolean) => void;
}

function GenerateShippingLabel({
  data,
  refetch,
  setIsLoading,
}: GenerateShippingLabelProps) {
  return (
    <button
      className={style["download-button"]}
      onClick={handleReserveShippingLabel}
    >
      Génerer un bon CHRONOPOST
      <SendIcon />
    </button>
  );

  async function handleReserveShippingLabel() {
    if (!data) {
      return;
    }
    if (!data.id) {
      return;
    }
    try {
      setIsLoading(true);
      const response =
        data.shippingDestinationType === "DIRECT"
          ? await reserveShippingEsdLabel(data.id, data)
          : await reserveShippingLabel(data.id);
      setIsLoading(false);
    } catch (error: any) {
      throw new Error(error.message);
    } finally {
      refetch();
    }
  }
}

interface ShippingLabelErrorNeedToCancelItProps {
  data: IShipping;
  refetch: () => void;
}

function ShippingLabelErrorNeedToCancelIt({
  data,
  refetch,
}: ShippingLabelErrorNeedToCancelItProps) {
  return (
    <div className={style["required-action"]}>
      <span className={style["required-action-message"]}>
        Données erronées veuillez annuler le Chronopost puis modifier l'adresse
        de livraison avant de générer un nouveau bon 😉{" "}
      </span>
      <div
        className={style["cancel-button"]}
        onClick={() => {
          if (data.id) {
            handleCancelShippingLabel(data.id);
          }
        }}
      >
        <CancelScheduleSend />
      </div>
    </div>
  );

  async function handleCancelShippingLabel(id: string) {
    try {
      const response = await cancelShippingLabel(id);
    } catch (error: any) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
      throw new Error(error.message);
    } finally {
      refetch();
    }
  }
}

interface NeedToEditProps {
  data: IShipping;
}

function NeedToEdit({ data }: NeedToEditProps) {
  const { setModalIsOpen } = useModalComponent();

  return (
    <div className={style["required-action"]}>
      <span>Veuillez sélectionner l'adresse de livraison (point relais)</span>
      <Link
        to={`shipping/edit/${data.id}`}
        onClick={() => setModalIsOpen(false)}
        className={style["edit-button"]}
      >
        <Edit />
      </Link>
    </div>
  );
}

interface DownloadShippingLabelProps {
  data: IShipping;
  refetch: () => void;
}

function DownloadShippingLabel({ data, refetch }: DownloadShippingLabelProps) {
  const [printMode, setPrintMode] = useState<string>("ZPL");

  async function handleCancelShippingLabel(id: string) {
    try {
      const response = await cancelShippingLabel(id);
    } catch (error: any) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
      throw new Error(error.message);
    } finally {
      refetch();
    }
  }

  return (
    <>
      {data.externalShipping && (
        <>
          <div className={style["external-id"]}>
            <Link
              onClick={() => window.scrollTo(0, 0)}
              target="_blank"
              to={`https://www.chronopost.fr/tracking-no-cms/suivi-page?listeNumerosLT=${data.externalShipping.externalId}&langue=fr`}
            >
              {data.externalShipping.externalId}
            </Link>
          </div>
          <div className={style["download-container"]}>
            <select
              onChange={({ target }) => setPrintMode(target.value)}
              className={style["select-print-mode"]}
            >
              {PRINTMODE.map((value: string[], index: number) => {
                return (
                  <option value={value[1]} key={index}>
                    {value[0]}
                  </option>
                );
              })}
            </select>
            {data.externalShipping && data.shippingMethod && (
              <>
                <div
                  className={style["download-button"]}
                  onClick={() =>
                    data.externalShipping &&
                    handleGetShippingLabel(
                      printMode,
                      data
                    )
                  }
                >
                  <Download />
                </div>
                <div
                  className={style["cancel-button"]}
                  onClick={() => {
                    if (data.id) {
                      handleCancelShippingLabel(data.id);
                    }
                  }}
                >
                  <CancelScheduleSend />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

function ErrorIncomingExternalShipping() {
  return (
    <div className={style["allday-right"]}>
      {`error incoming "externalShipping" missing`}
    </div>
  );
}

function ErrorOutgoingExternalShipping() {
  return (
    <div className={style["allday-right"]}>
      {`error outgoing "externalShipping" missing`}
    </div>
  );
}
