import { useMutation, useQuery } from "react-query";
import style from "./estimateModal.module.scss";
import { fetchEstimate } from "../../../requests/estimate";
import { useModalComponent } from "../../../context/modalComponent.context";
import ContactInfoCard from "../../contactInfoCard/contactInfoCard";
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";
import { StatusItem } from "../../statusItem/statusItem";
import { Edit, Mail, Phone } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ProductInformations } from "../../../routes/quickAccess/quoteEdit/quoteEdit";
import { setTocall } from "../../../requests/hubspot";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import Image from "../../image/Image";

interface EstimateModalProps {
  id: string;
}

export default function EstimateModal({ id }: EstimateModalProps) {
  const { data, isFetching, error } = useQuery("estimate-modal", () =>
    fetchEstimate({ params: { id: id } })
  );

  const setToCallMutation = useMutation(setTocall, {
    onSuccess: () => {
      toast.success("Rappel Hubspot programmé", TOAST_SUCCESS_OPTIONS);
    },
    onError: () => {
      toast.error("Une erreur est survenue", TOAST_ERROR_OPTIONS);
    },
  });

  const { setModalIsOpen } = useModalComponent();
  return (
    <div className={style["estimate-modal"]}>
      {!isFetching ? (
        <>
          {data && (
            <div className={style["read-document"]}>
              <ContactInfoCard
                contact={data?.contact}
                className={style["estimate-modal-contact-card"]}
                trackId={data?.trackId}
              />
              <div className={style["header"]}>
                <div className={style["header-left"]}>Demande d'estimation</div>
                <div className={style["header-right"]}>
                  <Link
                    onClick={handleEdit}
                    to={`/estimate/edit/${data.id}`}
                    className={style["tools-icon"]}
                  >
                    <Edit className={style["edit-icon"]} />
                  </Link>
                  <div className={style["state-container"]}>
                    <StatusItem
                      data={{ state: data.state, documentId: data.id }}
                    />
                  </div>
                </div>
              </div>
              <div className={style["body"]}>
                <div className={style["contact-info"]}>
                  {data.contact.displayname && (
                    <div className={style["contact-info-element"]}>
                      {data.contact.displayname}
                    </div>
                  )}
                  {data.contact.email && (
                    <Link
                      className={style["contact-info-element"]}
                      to={`mailto:${data.contact.email}`}
                    >
                      <Mail />
                      {data.contact.email}
                    </Link>
                  )}
                  {data.contact.phone && (
                    <Link
                      className={style["contact-info-element"]}
                      to={`tel:${data.contact.phone}`}
                    >
                      <Phone />
                      {data.contact.phone}
                    </Link>
                  )}
                  <div className={style["contact-info-hubspot"]}>
                    <button
                      onClick={() => {
                        setToCallMutation.mutate({
                          trackId: data.trackId as string,
                        });
                      }}
                      className={style["contact-info-hubspot-button"]}
                    >
                      Rappel Hubspot
                    </button>
                  </div>
                </div>
                <div className={style["comment"]}>{`“${data.comments}”`}</div>
                {data.imagesList.length > 0 && (
                  <label className={style["image-label"]}>Images : </label>
                )}
                <div className={style["images-list-container"]}>
                  <div className={style["images-list"]}>
                    {data.imagesList.map((imageUri: string, index: number) => {
                      return (
                        <Image
                          className={style["image-preview"]}
                          src={imageUri}
                          key={index}
                          index={index}
                          pictureList={data.imagesList}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className={style["product-information-container"]}>
                  <ProductInformations productsList={data.productsList} />
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className={style["loading-container"]}>
          <LoadingSpinner color="gold" />
        </div>
      )}
    </div>
  );

  function handleEdit() {
    window.scrollTo(0, 0);
    setModalIsOpen(false);
  }
}
