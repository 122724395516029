import { useQuery } from "react-query";
import { fetchShippingList } from "../../../requests/shipping";
import InputCode from "./inputCode";
import { IShipping } from "../../../types/shipping.types";
import { useState } from "react";
import ShippingSelection from "./shippingSelection/shippingSelection";
import style from "./incomingShipping.module.scss";
import InputContainer from "../../../components/forms/inputs/inputContainer/inputContainer";
import { Replay } from "@mui/icons-material";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";

export default function IncomingShipping() {
  const [shippingService, setShippingService] = useState<string>("CHRONO");

  return (
    <div className={style["incoming-shipping-container"]}>
      <h1>Réceptionner un colis</h1>
      <div className={style["shipping-service-container"]}>
        <div
          className={`${style["shipping-service-button"]} ${
            shippingService === "CHRONOPOST-2SHOP" ? style["toggled-button"] : ""
          }`}
          onClick={() => handleSelectShippingService("CHRONOPOST-2SHOP")}
        >
          Chronopost
        </div>
        <div
          className={`${style["shipping-service-button"]} ${
            shippingService === "CHRONOPOST-EUR" ? style["toggled-button"] : ""
          }`}
          onClick={() => handleSelectShippingService("CHRONOPOST-EUR")}
        >
          Chronopost Europe
        </div>
        <div
          className={`${style["shipping-service-button"]} ${
            shippingService === "GP" ? style["toggled-button"] : ""
          }`}
          onClick={() => handleSelectShippingService("GP")}
        >
          Collecte G&P
        </div>
      </div>
      <div className={style["incoming-shipping-bottom"]}>
        <ShippingService shippingService={shippingService} />
      </div>
    </div>
  );

  function handleSelectShippingService(name: string) {
    setShippingService(name);
  }
}

interface ShippingServiceProps {
  shippingService: string;
}

function ShippingService({ shippingService }: ShippingServiceProps) {
  switch (shippingService) {
    case "CHRONOPOST-2SHOP":
      return <IncomingChronopostShipping />;
    case "CHRONOPOST-EUR":
      return <IncomingChronopostEuropeShipping />;
    case "GP":
      return <IncomingInternalShipping />;
    default:
      return <></>;
  }
}

function IncomingChronopostEuropeShipping() {
  const [shippings, setShippings] = useState<null | IShipping[]>(null);
  return (
    <>
      <InputCode onFetched={handleShippingFetched} shippingService="CHRONOPOST-EUR"/>
      {shippings && <ShippingSelection shippings={shippings}/>}
    </>
  );

  function handleShippingFetched(data: IShipping[]) {
    const found = data.filter(
      (shipping: IShipping) =>
        shipping.state === "PENDING" || shipping.state === "TRANSITING"
    );
    if (found) setShippings(data);
  }
}


function IncomingChronopostShipping() {
  const [shippings, setShippings] = useState<null | IShipping[]>(null);
  return (
    <>
      <InputCode onFetched={handleShippingFetched} />
      {shippings && <ShippingSelection shippings={shippings} />}
    </>
  );

  function handleShippingFetched(data: IShipping[]) {
    const found = data.filter(
      (shipping: IShipping) =>
        shipping.state === "PENDING" || shipping.state === "TRANSITING"
    );
    if (found) setShippings(data);
  }
}

function IncomingInternalShipping() {
  const [date, setDate] = useState<Date>(new Date());
  const { data, isFetching } = useQuery(
    ["shippings-list", date],
    () => fetchShippingList(formatedQuery()),
    { refetchOnWindowFocus: false, keepPreviousData: true }
  );

  return (
    <>
      <div>
        <InputContainer>
          <label>Choisir une date</label>
          <div className={style["set-date-container"]}>
            <input
              type="date"
              onChange={handleDateChange}
              value={date.toISOString().substring(0, 10)}
            />
            <div
              className={style["reset-button"]}
              onClick={() => setDate(new Date())}
            >
              <Replay />
            </div>
          </div>
        </InputContainer>
      </div>
      {isFetching ? (
        <div className={style["loading-spinner-container"]}>
          <LoadingSpinner color="gold" />
        </div>
      ) : (
        <ShippingSelection shippings={data?.shippingsList} />
      )}
    </>
  );

  function handleDateChange({ target }: { target: HTMLInputElement }) {
    setDate(new Date(target.value));
  }

  function formatedQuery() {
    return `?period.start=${date.toISOString()}&period.start=${date.toISOString()}&shippingMethod.shippingService=INTERNAL&direction=INCOMING&state=PENDING`;
  }
}
