import { Link, useNavigate } from "react-router-dom";
import { IShippingPro } from "../../../types/shipping.types";
import style from "./outgoingShippingProModal.module.scss";
import { LocationOn, Mail, Phone } from "@mui/icons-material";
import ValidateButton from "../../validateButton/validateButton";
import { useContext, useEffect, useMemo, useState } from "react";
import { ModalComponentContext, useModalComponent } from "../../../context/modalComponent.context";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import {
  cancelShippingProLabel,
  finishShippingPro,
  generateShippingAndChronopost,
  getShippingProReservationNumberById,
  reserveShippingProLabel,
} from "../../../requests/shippingPro";
import { getShippingLabel } from "../../../requests/shipping";
import ToastedLink from "../../ToastedLink/ToastedLink";
import { isDesktop } from "react-device-detect";

interface OutgoingShippingProModalProps {
  shippingPro: IShippingPro;
  shopName?: string;
}

export default function OutgoingShippingProModal({
  shippingPro,
  shopName,
}: OutgoingShippingProModalProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pdfLink, setPdfLink] = useState<string>("");
  const { setModalIsOpen } = useModalComponent();
  const navigate = useNavigate();
  const IS_CHRONOPOST =
    shippingPro.shippingMethod.shippingService.includes("CHRONOPOST");

  return (
    <div className={style["outgoing-shipping-pro-modal"]}>
      <div className={style["outgoing-shipping-pro-modal-container"]}>
        <div>
          <div className={style["title"]}>
            {"Livraison du "}
            {new Date(shippingPro.shippingDate || "").toLocaleDateString()}
          </div>
          <div className={style["outgoing-shipping-pro-body"]}>
            <div className={style["shipping-info"]}>
              <span className={style["organization"]}>
                {shippingPro.recipient.organization}
              </span>
              <span className={style["shipping-info-item"]}>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  target="_blank"
                  className={style["address-icon"]}
                  to={`http://maps.google.com/?q=${shippingPro.recipient.address1} ${shippingPro.recipient.address2} ${shippingPro.recipient.zipCode} ${shippingPro.recipient.city}`}
                >
                  <LocationOn />
                </Link>
                {shippingPro.recipient.address1}
              </span>
              <span className={style["shipping-info-item"]}>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className={style["address-icon"]}
                  to={`mailto:${shippingPro.recipient.email}`}
                >
                  <Mail />
                </Link>
                {shippingPro.recipient.email}
              </span>
              <span className={style["shipping-info-item"]}>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className={style["address-icon"]}
                  to={`tel:${shippingPro.recipient.phone}`}
                >
                  <Phone />
                </Link>
                {shippingPro.recipient.phone}
              </span>
              <span>Nombre d'articles: {shippingPro.trackId?.length}</span>
            </div>
          </div>
        </div>
        <div className={style["button-container"]}>
          <ValidateButton
            title={"Valider"}
            handleOnClick={handleValidateShipping}
            isLoading={isLoading}
            loadingSpinnerStyle={style["validate-button"]}
          />
        </div>
      </div>
    </div>
  );

  async function handleValidateShipping() {
    if (!shippingPro.id || !shippingPro.shopId) {
      toast.error("Shipping ID is missing", TOAST_ERROR_OPTIONS);
      return;
    }
    setIsLoading(true);
    try {
      await finishShippingPro(
        shippingPro.id,
        shippingPro.direction,
        shippingPro.shopId,
        "TRANSITING"
      );
      setModalIsOpen(false);
      navigate("/quick-access/pro-shipping?direction=OUTGOING");
      toast.success(
        <ToastedLink
          message={"Livraison finalisée avec succès."}
          linkMessage={`${IS_CHRONOPOST ? "Génerer bon CHRONOPOST" : ""}`}
          handleOnLinkClick={() => navigate(`/shops?name=${shopName}`)}
        />,
        TOAST_SUCCESS_OPTIONS
      );
    } catch (error) {
      toast.error(
        "Un problème est survenu lors de la fin de la collecte",
        TOAST_ERROR_OPTIONS
      );
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  // function generateIncomingShippingFromOutgoingShipping(): IShippingPro {
  //   function getShippingPeriod() {
  //     if (!nextIncomingShipping) {
  //       return { start: null, end: null };
  //     }
  //     return {
  //       start: new Date(nextIncomingShipping.date),
  //       end: new Date(nextIncomingShipping.date),
  //     };
  //   }

  //   const incomingShippingPro: IShippingPro = {
  //     state: "PENDING",
  //     trackId: [],
  //     shopId: shippingPro.shopId,
  //     sender: shippingPro.recipient,
  //     recipient: shippingPro.sender,
  //     direction: "INCOMING",
  //     shippingDate: nextIncomingShipping?.date,
  //     period: getShippingPeriod(),
  //     shippingMethod: shippingPro.shippingMethod,
  //     externalShipping: {
  //       reservationNumber: "",
  //       externalId: "",
  //       pickUpId: "",
  //     },
  //   };

  //   return incomingShippingPro;
  // }

  // function createPdfLink(data: any, mode: string) {
  //   const uint8Array = new Uint8Array(data);
  //   const blob = new Blob([uint8Array], {
  //     type: mode === "ZPL" ? "x-application/zpl" : "application/pdf",
  //   });
  //   const link = URL.createObjectURL(blob);
  //   return link;
  // }

  // async function handleValidateShippingAndCreateLabel() {
  //   if (shippingPro.externalShipping?.reservationNumber) {
  //     return setModalIsOpen(false);
  //   }
  //   if (!shippingPro.id) {
  //     toast.error("Shipping ID is missing", TOAST_ERROR_OPTIONS);
  //     return;
  //   }
  //   setIsLoading(true);
  //   try {
  //     const { shippingPro: incomingShippingPro } =
  //       await generateShippingAndChronopost(
  //         generateIncomingShippingFromOutgoingShipping()
  //       );
  //     if (!incomingShippingPro || !incomingShippingPro.externalShipping) {
  //       toast.error("Incoming Chronopost Label creation", TOAST_ERROR_OPTIONS);
  //       return;
  //     }
  //     const { reservationNumber } = await reserveShippingProLabel(
  //       shippingPro.id
  //     );
  //     if (!reservationNumber) {
  //       toast.error("Chronopost Label creation", TOAST_ERROR_OPTIONS);
  //       return;
  //     }
  //     const responsePdf: any = await getShippingLabel(reservationNumber, "ZPL");
  //     if (!responsePdf) {
  //       toast.error("Failed to create Label", TOAST_ERROR_OPTIONS);
  //       return;
  //     }
  //     const responseIncomingPdf: any = await getShippingLabel(
  //       incomingShippingPro.externalShipping?.reservationNumber,
  //       "ZPL"
  //     );
  //     if (!responseIncomingPdf) {
  //       toast.error("Failed to create Label", TOAST_ERROR_OPTIONS);
  //       return;
  //     }
  //     setPdfLink(createPdfLink(responsePdf.data, "ZPL"));
  //     setIncomingPdfLink(createPdfLink(responseIncomingPdf.data, "ZPL"));
  //   } catch (error) {
  //     toast.error(
  //       "Un problème est survenu lors de la fin de la collecte",
  //       TOAST_ERROR_OPTIONS
  //     );
  //     console.error(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  // async function handleGetShippingLabel(
  //   reservationNumber: string,
  //   mode: string,
  //   setPdfLink: (link: string) => void
  // ) {
  //   try {
  //     const response: any = await getShippingLabel(reservationNumber, mode);

  //     const uint8Array = new Uint8Array(response.data);
  //     const blob = new Blob([uint8Array], {
  //       type: mode === "ZPL" ? "x-application/zpl" : "application/pdf",
  //     });

  //     const downloadLink = URL.createObjectURL(blob);
  //     setPdfLink(downloadLink);
  //   } catch (error: any) {
  //     toast.error(
  //       "Un problème est survenu lors du téléchargement du bon Chronopost",
  //       TOAST_ERROR_OPTIONS
  //     );
  //     console.error(error);
  //   }
  // }

  // async function handleGetIncomingShippingLabel(id: string) {
  //   try {
  //     const { reservationNumber } = await getShippingProReservationNumberById(
  //       id
  //     );
  //     if (!reservationNumber) {
  //       toast.error(
  //         "Un problème est survenu lors du téléchargement du bon Chronopost de collecte",
  //         TOAST_ERROR_OPTIONS
  //       );
  //     }
  //     const response: any = await getShippingLabel(reservationNumber, mode);

  //     const uint8Array = new Uint8Array(response.data);
  //     const blob = new Blob([uint8Array], {
  //       type: mode === "ZPL" ? "x-application/zpl" : "application/pdf",
  //     });

  //     const downloadLink = URL.createObjectURL(blob);
  //     setIncomingPdfLink(downloadLink);
  //   } catch (error: any) {
  //     console.error(error);
  //   }
  // }

  // async function handleCancelShippingLabel(id?: string) {
  //   if (!id) {
  //     toast.error("Shipping ID is missing", TOAST_ERROR_OPTIONS);
  //     return;
  //   }
  //   setIsLoadingCancel(true);
  //   try {
  //     await cancelShippingProLabel(id);
  //     toast.success(
  //       "Le bon Chronppost a été annulé avec succès",
  //       TOAST_SUCCESS_OPTIONS
  //     );
  //   } catch (error: any) {
  //     console.error(error);
  //   } finally {
  //     setIsLoadingCancel(false);
  //     setSelectedPdf("");
  //   }
  // }
}
