import ProductModal from "../../../../components/modals/productModal/ProductModal";
import WorkshopId from "../../../../components/workshopId/workshopId";
import { useModalComponent } from "../../../../context/modalComponent.context";
import { getOperationsListAcronym } from "../../../../translation/operation";
import { IProduct } from "../../../../types/logistic.types";
import { dateDiffInDays } from "../../../../utils/utils";
import style from "./productCard.module.scss";

interface ProductCardProps {
  product: IProduct;
  showOperationAcronym?: boolean;
}

export default function ProductCard({
  product,
  showOperationAcronym = false,
}: ProductCardProps) {
  const { setModalIsOpen, setModalChildren } = useModalComponent();
  const operationAcronym = getOperationsListAcronym(product.operationsList);

  return (
    <div className={style["product-card"]} onClick={handleOpenModal}>
      <div className={style["product-card-left"]}>
        <WorkshopId
          className={style["workshop-id"]}
          workshopId={product.workshopId}
          size="small"
        />
        <div className={style["brand"]}>{product.brand}</div>
        {showOperationAcronym && (
          <div className={style["operations-acronym-list"]}>
            {operationAcronym.map((opAcro) => {
              return (
                <div className={style["operations-acronym"]}>{opAcro}</div>
              );
            })}
          </div>
        )}
      </div>
      {product.dueDate && (
        <div className={style["due-date"]}>
          {`${new Date(product.dueDate).toLocaleDateString("fr-FR")} `}
          <span>
            {`(${dateDiffInDays(new Date(), new Date(product.dueDate))} jours)`}
          </span>
        </div>
      )}
    </div>
  );

  function handleOpenModal() {
    setModalChildren(<ProductModal product={product} />);
    setModalIsOpen(true);
  }
}
