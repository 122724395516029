import style from "./index.module.scss";
import Item from "./listItem/item";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { IProAccount } from "../../types/proAccount.types";
import { deleteProAccount, getProAccountList } from "../../requests/proAccount";
import { queryObject } from "../../types/query.types";
import { useEffect, useState } from "react";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import useSaveQueryStorage, {
  loadQueryStorage,
} from "../../hooks/useSaveQueryStorage";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Button from "../../components/Button/Button";

let controller = new AbortController();

export const INITIAL_QUERY: queryObject = {
  direction: "",
  limit: 10,
  offset: 0,
  sort: "",
  dateFrom: "",
  dateTo: "",
};

const PRO_SEARCH_OPTIONS = ["name"];

export default function ProAccounts() {
  const [query, setQuery] = useState<queryObject>(
    loadQueryStorage(INITIAL_QUERY, "pro-account")
  );
  const { refetch, isLoading, data }: any = useQuery(
    ["userList", query],
    () => getProAccountList(formatedQuery(), controller.signal),
    { keepPreviousData: true }
  );
  useSaveQueryStorage(query, "pro-account");

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["item-list"]}>
      <div className={style["top"]}>
        <span className={style["title"]}>Comptes professionnel</span>
        <Link onClick={() => window.scrollTo(0, 0)} to={"./create"}>
          <Button>+ Ajouter nouveau</Button>
        </Link>
      </div>
      <div className={style["filters"]}>
        <SearchByKeyComponent
          query={query}
          setQuery={setQuery}
          optionsList={PRO_SEARCH_OPTIONS}
        />
      </div>
      <div className={style["bottom"]}>
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
        <div className={style["item"]}>
          <div className={style["item-element"]}>Nom d'utilisateur</div>
          <div className={style["item-element"]}>Actions</div>
        </div>
        <div>
          {data &&
            data.proAccount &&
            data.proAccount.map((value: IProAccount, key: number) => {
              return <Item key={key} data={value} onDelete={handleDelete} />;
            })}
        </div>
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
      </div>
    </div>
  );

  async function handleDelete(id: string) {
    await deleteProAccount(id);
    refetch();
  }

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery = formatedQuery + `?limit=${query.limit}`;
    }

    formatedQuery = formatedQuery + `&offset=${query.offset * query.limit}`;

    if (query.field && query.value) {
      formatedQuery = formatedQuery + `&${query.field}=${query.value}`;
    }
    if (query.sort) {
      formatedQuery = formatedQuery + `${query.sort}`;
    }
    if (!(query.dateFrom === "" && query.dateTo === "")) {
      formatedQuery =
        formatedQuery +
        `&createdAt=` +
        query.dateFrom +
        `&createdAt=` +
        query.dateTo;
    }
    return formatedQuery;
  }
}
