import { FormEvent, useContext, useEffect, useRef } from "react";
import style from "./paymentModal.module.scss";
import { ITracker } from "../../../types/tracker.types";
import InputContainer from "../../forms/inputs/inputContainer/inputContainer";
import { useMutation, useQuery } from "react-query";
import { addPayment, addPaymentOrder } from "../../../requests/payment";
import { IForcedPayment } from "../../../types/payment.type";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import { useModalComponent } from "../../../context/modalComponent.context";
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";
import { useRevalidator } from "react-router-dom";
import { fetchQuote } from "../../../requests/quote";

interface PaymentModalProps {
  tracker: ITracker;
}

function formatDate(value: string, pattern: string) {
  let i = 0;
  let v = value.toString().replace(/[^0-9 ]/g, "");
  return pattern.replace(/#/g, (_: any) => v[i++]);
}

export default function PaymentModal({ tracker }: PaymentModalProps) {
  const { setModalIsOpen } = useModalComponent();
  const revalidator = useRevalidator();

  const query = useQuery("paymentQuote", () =>
    fetchQuote(tracker.quote.documentId)
  );

  const addPaymentMutation = useMutation(addPayment, {
    onError: () => {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
    onSuccess: () => {
      toast.success("Paiement validé !", TOAST_SUCCESS_OPTIONS);
      setModalIsOpen(false);
      revalidator.revalidate();
    },
  });

  const addPaymentOrderMutation = useMutation(addPaymentOrder, {
    onError: () => {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
    onSuccess: (data) => {
      if (data.paymentURL) {
        toast.success(data.paymentURL, {
          ...TOAST_SUCCESS_OPTIONS,
          closeOnClick: false,
          draggable: false,
          autoClose: false,
        });
        navigator.clipboard.writeText(data.paymentURL).then(() => {
          toast.success(
            "Lien de paiement copié dans le presse-papier !",
            TOAST_SUCCESS_OPTIONS
          );
        });
      }
      setModalIsOpen(false);
    },
  });

  const amountRef = useRef<HTMLInputElement>(null);
  const paymentType = useRef<HTMLSelectElement>(null);
  return (
    <div className={style["payment-modal"]}>
      <div className={style["payment-form"]}>
        {query.isLoading || addPaymentMutation.isLoading ? (
          <LoadingSpinner color={"gold"} />
        ) : (
          <form onSubmit={handleSubmit}>
            <div>Montant devis: {query?.data?.total} €</div>
            <InputContainer>
              <label>Montant</label>
              <input ref={amountRef} type="number" step={0.01}></input>
            </InputContainer>

            <InputContainer>
              <label>Type</label>
              <select ref={paymentType}>
                <option value="link">Lien de paiement</option>
                <option value="forced">Forcé</option>
              </select>
            </InputContainer>
            <button type="submit">Valider</button>
          </form>
        )}
      </div>
    </div>
  );

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    if (
      amountRef.current?.value === null ||
      amountRef.current?.value === undefined ||
      isNaN(parseInt(amountRef.current.value))
    ) {
      toast.error("Montant invalide.", TOAST_ERROR_OPTIONS);
      return;
    }
    if (
      paymentType.current?.value !== "link" &&
      paymentType.current?.value !== "forced"
    ) {
      toast.error("Type invalide.", TOAST_ERROR_OPTIONS);
      return;
    }
    let amount = parseInt(amountRef.current.value);
    let type = paymentType.current.value;
    if (
      !window.confirm(
        `Forcer un paiement de ${amountRef.current.value} € pour un devis de ${query.data?.total} € ?`
      )
    ) {
      setModalIsOpen(false);
      return;
    }
    let formattedDate = formatDate(new Date().toISOString(), "##############");
    const payment: IForcedPayment = {
      vads_order_info: "B2C-BOOKING",
      vads_trans_id: "000000",
      vads_trans_status: "AUTHORISED",
      vads_trans_date: formattedDate,
      vads_amount: amount * 100,
      vads_cust_email: tracker.contact.email,
      vads_cust_id: tracker.contact.documentId,
      vads_order_id: tracker.id,
    };
    if (type === "link") {
      addPaymentOrderMutation.mutate(payment);
      return;
    } else if (type === "forced") {
      addPaymentMutation.mutate(payment);
      return;
    }
  }
}
