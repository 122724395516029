import style from "./index.module.scss";
import Item from "./listItem/item";
import { useState } from "react";
import { deleteLogistic, fetchLogisticList } from "../../requests/logistic";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { ProtectedComponent } from "../../components/protectedComponent/protectedComponent";
import ListHeaderComponent from "../../components/listHeaderComponent/listHeaderComponent";
import { INITIAL_QUERY } from "../../utils/query.init";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import FilterByStatus from "../../components/filterByStatus/filterByStatus";
import { LOGISTIC_STATUS } from "../../utils/logistic.init";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import { queryObject } from "../../types/query.types";
import { useRefetchOnModalClose } from "../../hooks/useRefetchOnModalClose";
import useSaveQueryStorage, {
  loadQueryStorage,
} from "../../hooks/useSaveQueryStorage";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Button from "../../components/Button/Button";

const OPTIONS_LIST = [
  "productsList.workshopId",
  "contact.displayname",
  "contact.organization",
  "contact.phone",
  "contact.email",
  "productsList.brand",
];

let controller = new AbortController();

export default function Logistic() {
  const [query, setQuery] = useState<queryObject>(
    loadQueryStorage(INITIAL_QUERY, "logistic")
  );
  const { data, isLoading, refetch } = useQuery(
    ["logistic", query],
    () => fetchLogisticList(queryFormatter()),
    { keepPreviousData: true, refetchOnWindowFocus: true }
  );
  useSaveQueryStorage(query, "logistic");
  useRefetchOnModalClose(refetch);

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["item-list"]}>
      <div className={style["top"]}>
        <span className={style["title"]}>Documents Logistiques</span>
        <Link onClick={() => window.scrollTo(0, 0)} to={"./create"}>
          <ProtectedComponent roles={["ROLE_ADMIN", "ROLE_LOGISTICS"]}>
            <Button>+ Ajouter nouveau</Button>
          </ProtectedComponent>
        </Link>
      </div>
      <div className={style["filters-container"]}>
        <div className={style["filters"]}>
          <SearchByKeyComponent
            query={query}
            setQuery={setQuery}
            optionsList={OPTIONS_LIST}
          />
          <FilterByStatus
            query={query}
            setQuery={setQuery}
            optionsList={LOGISTIC_STATUS}
            field={"status"}
          />
        </div>
      </div>
      <div className={style["bottom"]}>
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
        <div className={style["logistic-list-container"]}>
          <div className={style["logistic-list"]}>
            <div className={style["header"]}>
              <div className={style["header-left"]}>
                <div className={style["header-element-container"]}>
                  Coordonnée
                </div>
                <div className={style["header-element-container"]}>
                  Date de création
                </div>
                <div className={style["header-element-container"]}>
                  Articles
                </div>
                <div className={style["header-element-container"]}>Status</div>
              </div>
              <div
                className={[
                  style["header-element-container"],
                  style["action-header"],
                ].join(" ")}
              >
                Actions
              </div>
            </div>
            {data &&
              data.logisticsList &&
              data.logisticsList.map((value: any, index: number) => {
                return (
                  <Item
                    key={index}
                    data={value}
                    zIndex={query.limit - index}
                    onDelete={handleDelete}
                    refetch={refetch}
                  />
                );
              })}
          </div>
        </div>
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
      </div>
    </div>
  );

  async function handleDelete(id: string) {
    controller.abort();
    try {
      controller = new AbortController();
      await deleteLogistic(id, controller.signal);
      refetch();
    } catch (error) {
      console.error(error);
    }
  }

  function queryFormatter() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery += `?limit=${query.limit}`;
    }

    formatedQuery += `&offset=${query.offset * query.limit}`;

    if (query.state && query.state != "Tous") {
      formatedQuery += `&status=${query.state}`;
    }
    if (query.field && query.value) {
      formatedQuery += `&${query.field}=${query.value}`;
    }
    if (query.sort) {
      formatedQuery += `${query.sort}`;
    } else {
      formatedQuery += `&sort_field=createdAt&sort_direction=desc`;
    }
    return formatedQuery;
  }
}
