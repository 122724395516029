import { useEffect, useLayoutEffect, useRef, useState } from "react";

export default function useElementDimensions<T extends HTMLElement>(
  deps?: any[]
): [React.RefObject<T>, number | undefined, number | undefined] {
  const ref = useRef<T>(null);
  const [width, setWidth] = useState<number | undefined>();
  const [height, setHeight] = useState<number | undefined>();

  useLayoutEffect(() => {
    if (ref.current && ref.current.offsetWidth && ref.current.offsetHeight) {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.offsetHeight);
    }
  }, [ref, deps]);

  return [ref, width, height];
}
