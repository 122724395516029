import style from "./climbingItemCard.module.scss";
import { IOperation, IProduct } from "../../../../../types/proCart.types";
import WorkshopId from "../../../../../components/workshopId/workshopId";
import Button from "../../../../../components/Button/Button";
import { useConfirmModal } from "../../../../../context/confirmationModalContext";
import ConfirmModalComp from "../../../../../components/confirmationModal/ConfirmModalComp";

interface ClimbingItemCardProps {
  operationsList: IOperation[];
  product: IProduct;
  updateCartOperation: (operation: IOperation) => void;
}

export default function ClimbingItemCard({
  operationsList,
  product,
  updateCartOperation,
}: ClimbingItemCardProps) {
  const { setConfirmModalIsOpen, setConfirmModalChildren } = useConfirmModal();
  const professionalDiscount = product.operationsList[0].professionalDiscount;

  return (
    <div className={style["climbing-card"]}>
      <div className={style["climbing-card-left"]}>
        <label>Marque</label>
        <div className={style["brand"]}>{product.brand || "-"}</div>
        {product.comments && (
          <div className={style["comment"]}>"{product.comments}"</div>
        )}
        <label>Type de gomme</label>
        <div className={style["rubber-type"]}>
          {product.operationsList[0].comments}
        </div>
      </div>
      <div className={style["climbing-card-right"]}>
        <WorkshopId workshopId={product.workshopId} size="xx-small" />
        <div className={style["operation-selection"]}>
          {operationsList.map((operation: IOperation, index: number) => {
            return (
              <Button
                key={index}
                className={buttonStyle(operation.slug, operation.description)}
                onClick={() =>
                  handleSetOperation({ ...operation, professionalDiscount })
                }
              >
                {operation.description}
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );

  function buttonStyle(slug: string, description: string) {
    if (
      product.operationsList[0].slug === slug ||
      product.operationsList[0].description === description
    ) {
      return style["operation-button"] + " " + style["selected"];
    }
    return style["operation-button"];
  }

  function handleSetOperation(operation: IOperation) {
    if (product.operationsList[0].slug === operation.slug) {
      return;
    }
    setConfirmModalIsOpen(true);
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={style["validate-button"]}
        title={`Changer l'opération en "${operation.description}" ?`}
        message={`${product.workshopId} - ${product.brand || "-"}`}
        confirmText="Valider"
        onConfirm={() =>
          updateCartOperation({
            ...operation,
            comments: product.operationsList[0].comments,
          })
        }
      />
    );
  }
}
