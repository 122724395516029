import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import { deleteOperation, fetchOperationsList } from "../../requests/operation";
import style from "./index.module.scss";
import Item from "./item/item";
import { ProtectedComponent } from "../../components/protectedComponent/protectedComponent";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import { Replay } from "@mui/icons-material";
import {
  OPERATION_GROUP,
  OPERTATION_TYPE,
  PRODUCT_GROUP,
} from "../../utils/logistic.init";
import translate from "../../translation";
import { IOperation } from "../../types/accounting.types";
import { queryObject } from "../../types/query.types";
import useSaveQueryStorage, {
  loadQueryStorage,
} from "../../hooks/useSaveQueryStorage";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Button from "../../components/Button/Button";
import Select from "../../components/Select/Select";

let controller = new AbortController();

export const OPERATION_INITIAL_QUERY: queryObject = {
  limit: 10,
  offset: 0,
  operationType: "Tous",
  productGroup: "Tous",
  operationGroup: "Tous",
};

export default function Operations() {
  const [query, setQuery] = useState<queryObject>(
    loadQueryStorage(OPERATION_INITIAL_QUERY, "operation")
  );
  const { data, isLoading, error, refetch } = useQuery(
    ["operation", query],
    () => fetchOperationsList(formatedQuery()),
    { keepPreviousData: true }
  );
  useSaveQueryStorage(query, "operation");

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["item-list"]}>
      <div className={style["top"]}>
        <span className={style["title"]}>Opérations</span>
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <Link onClick={() => window.scrollTo(0, 0)} to={"./create"}>
            <Button>+ Ajouter nouveau</Button>
          </Link>
        </ProtectedComponent>
      </div>
      <div className={style["filters-container"]}>
        <div className={style["filters"]}>
          <div className={style["filter-element"]}>
            <label>Groupe d'opérations</label>
            <Select
              name={"operationGroup"}
              optionsList={OPERATION_GROUP}
              value={query.operationGroup || "Tous"}
              setValue={(value) =>
                setQuery((prev) => ({ ...prev, operationGroup: value }))
              }
            />
          </div>
          <div className={style["filter-element"]}>
            <label>Type d'opérations</label>
            <Select
              name={"operationType"}
              optionsList={OPERTATION_TYPE}
              value={query.operationType || "Tous"}
              setValue={(value) =>
                setQuery((prev) => ({ ...prev, operationType: value }))
              }
            />
          </div>
          <div className={style["filter-element"]}>
            <label>Groupe d'articles</label>
            <Select
              name={"productGroup"}
              optionsList={PRODUCT_GROUP}
              value={query.productGroup || "Tous"}
              setValue={(value) =>
                setQuery((prev) => ({ ...prev, productGroup: value }))
              }
            />
          </div>
        </div>
        {query !== OPERATION_INITIAL_QUERY && (
          <div
            className={style["reset-filters"]}
            onClick={() => setQuery(OPERATION_INITIAL_QUERY)}
            title="réinitialiser"
          >
            <Replay />
          </div>
        )}
      </div>
      <div className={style["bottom"]}>
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
        <div className={style["item"]}>
          <div className={style["item-element"]}>Description</div>
          <div className={style["item-element"]}>Groupe d'opérations</div>
          <div className={style["item-element"]}>Type d'opérations</div>
          <div className={style["item-element"]}>Groupe d'articles</div>
          <div className={style["item-element"]}>Prix</div>
          <div className={style["item-element"]}>Commentaire</div>
          <ProtectedComponent roles={["ROLE_ADMIN"]}>
            <div className={style["item-element"]}>Actions</div>
          </ProtectedComponent>
        </div>
        {data &&
          data.operations &&
          data.operations.map((value: IOperation, key: number) => {
            return (
              <Item
                key={key}
                data={value}
                onDelete={handleDelete}
                refetch={refetch}
              />
            );
          })}
      </div>
    </div>
  );

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery = formatedQuery + `limit=${query.limit}`;
    }

    formatedQuery = formatedQuery + `&offset=${query.offset * query.limit}`;

    if (query.sort) {
      formatedQuery = formatedQuery + query.sort;
    }

    if (query.operationGroup && query.operationGroup !== "Tous") {
      formatedQuery += `&operationGroup=${query.operationGroup}`;
    }

    if (query.operationType && query.operationType !== "Tous") {
      formatedQuery += `&operationType=${query.operationType}`;
    }

    if (query.productGroup && query.productGroup !== "Tous") {
      formatedQuery += `&productGroup=${query.productGroup}`;
    }

    if (query.field && query.value) {
      formatedQuery = formatedQuery + `&${query.field}=${query.value}`;
    }

    return formatedQuery;
  }

  async function handleDelete(slug: string) {
    controller.abort();
    try {
      controller = new AbortController();
      await deleteOperation({ slug: slug, signal: controller.signal });
      refetch();
    } catch (error) {
      console.error(error);
    }
  }

  function handleFilterChange({
    target,
  }: {
    target: EventTarget & HTMLSelectElement;
  }) {
    setQuery((prev) => ({ ...prev, [target.name]: target.value }));
  }
}
