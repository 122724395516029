import { useEffect, useState } from "react";
import style from "./index.module.scss";
import { useQuery } from "react-query";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import ListHeaderComponent from "../../components/listHeaderComponent/listHeaderComponent";
import Item from "./item/item";
import useLogout from "../../hooks/useLogout";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import FilterByStatus from "../../components/filterByStatus/filterByStatus";
import { queryObject } from "../../types/query.types";
import { fetchPaymentsList } from "../../requests/payment";
import { IPayment } from "../../types/payment.type";
import { useAuth } from "../../context/auth.context";
import useSaveQueryStorage, {
  loadQueryStorage,
} from "../../hooks/useSaveQueryStorage";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";

export const INITIAL_QUERY: queryObject = {
  field: "contact.displayname",
  value: "",
  service: "",
  direction: "",
  status: "",
  limit: 10,
  offset: 0,
  sort: "",
  dateFrom: "",
  dateTo: "",
  state: "",
};

const PAYMENT_SEARCH_OPTIONS = [
  "contact.displayname",
  "contact.phone",
  "contact.organization",
  "contact.email",
];

export const PAYMENT_STATE = ["AUTHORISED", "REFUSED"];

export default function Carts() {
  const [query, setQuery] = useState<queryObject>(
    loadQueryStorage(INITIAL_QUERY, "payment")
  );
  const { data, isLoading } = useQuery(
    ["payments", query],
    () => fetchPaymentsList(formatedQuery()),
    { keepPreviousData: true }
  );
  const { roles } = useAuth();
  useSaveQueryStorage(query, "payment");

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["item-list"]}>
      <div className={style["top"]}>
        <span className={style["title"]}>Paiements</span>
      </div>
      <div className={style["filters-container"]}>
        <div className={style["filters"]}>
          <SearchByKeyComponent
            query={query}
            setQuery={setQuery}
            optionsList={PAYMENT_SEARCH_OPTIONS}
          />
          <FilterByStatus
            query={query}
            setQuery={setQuery}
            optionsList={PAYMENT_STATE}
            field={"transactionStatus"}
          />
        </div>
      </div>
      <div className={style["bottom"]}>
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
        <div className={style["item"]}>
          <div className={style["item-element"]}>Coordonnées</div>
          <ListHeaderComponent
            name={"transactionDate"}
            title="Date de transaction"
            headerClassName={style["item-element"]}
            query={query}
            setQuery={setQuery}
          />
          <div className={style["item-element"]}>Montant</div>
          <div className={style["item-element"]}>Status</div>
          <div className={style["item-element"]}>Actions</div>
        </div>
        {data &&
          data.payments &&
          data.payments.map((payment: IPayment, key: number) => {
            if (roles && roles.includes("ROLE_ADMIN")) {
              return <Item key={key} data={payment} />;
            }
          })}
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
      </div>
    </div>
  );

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery = formatedQuery + `?limit=${query.limit}`;
    }

    formatedQuery = formatedQuery + `&offset=${query.offset * query.limit}`;

    if (query.field && query.value) {
      formatedQuery = formatedQuery + `&${query.field}=${query.value}`;
    }
    if (query.sort) {
      formatedQuery = formatedQuery + `&sort=${query.sort}`;
    } else {
      formatedQuery += `&sort_field=transactionDate&sort_direction=desc`;
    }

    if (query.state && query.state !== "Tous") {
      formatedQuery = formatedQuery + `&transactionStatus=${query.state}`;
    }

    if (!(query.dateFrom === "" && query.dateTo === "")) {
      formatedQuery =
        formatedQuery +
        `&transactionDate=` +
        query.dateFrom +
        `&transactionDate=` +
        query.dateTo;
    }
    return formatedQuery;
  }

  function formatDate(date: string | undefined) {
    return date !== ""
      ? date && new Date(date).toISOString().substring(0, 10)
      : "";
  }
}
