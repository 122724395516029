import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import { deleteEstimate, fetchEstimatesList } from "../../requests/estimate";
import style from "./index.module.scss";
import Item from "./listItem/item";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import ListHeaderComponent from "../../components/listHeaderComponent/listHeaderComponent";
import { ESTIMATE_STATE } from "../../utils/booking.init";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import FilterByStatus from "../../components/filterByStatus/filterByStatus";
import { queryObject } from "../../types/query.types";
import useSaveQueryStorage, {
  loadQueryStorage,
} from "../../hooks/useSaveQueryStorage";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Button from "../../components/Button/Button";

let controller = new AbortController();

export const INITIAL_QUERY: queryObject = {
  field: "contact.phone",
  value: "",
  state: "",
  limit: 10,
  offset: 0,
};

const ESTIMATE_SEARCH_OPTIONS = [
  "contact.phone",
  "contact.displayname",
  "contact.organization",
  "contact.email",
];

export default function Estimates() {
  const [query, setQuery] = useState<queryObject>(
    loadQueryStorage(INITIAL_QUERY, "estimate")
  );
  const { data, isLoading, refetch } = useQuery(
    ["estimate", query],
    () => fetchEstimatesList(formatedQuery()),
    { keepPreviousData: true }
  );
  useSaveQueryStorage(query, "estimate");

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["item-list"]}>
      <div className={style["top"]}>
        <span className={style["title"]}>Demandes d'estimation</span>
        <Link onClick={() => window.scrollTo(0, 0)} to={"./create"}>
          <Button>+ Ajouter nouveau</Button>
        </Link>
      </div>
      <div className={style["filters-container"]}>
        <div className={style["filters"]}>
          <SearchByKeyComponent
            query={query}
            setQuery={setQuery}
            optionsList={ESTIMATE_SEARCH_OPTIONS}
          />
          <FilterByStatus
            query={query}
            setQuery={setQuery}
            optionsList={ESTIMATE_STATE}
            field={"status"}
            // isTracking
          />
        </div>
      </div>
      <div className={style["bottom"]}>
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
        <div className={style["item"]}>
          <div className={style["item-element"]}>Nom / Organisation</div>
          <ListHeaderComponent
            name={"createdAt"}
            title="Date de création"
            headerClassName={style["item-element"]}
            query={query}
            setQuery={setQuery}
          />
          <div className={style["item-element"]}>Articles</div>
          <div className={style["item-element"]}>Status</div>
          <div className={style["item-element"]}>Actions</div>
        </div>
        {data &&
          data.estimatesList &&
          data.estimatesList.map((value: any, index: number) => {
            return (
              <Item
                key={index}
                data={value}
                index={query.limit - index}
                onDelete={handleDelete}
              />
            );
          })}
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
      </div>
    </div>
  );

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery += `?limit=${query.limit}`;
    }

    formatedQuery += `&offset=${query.offset * query.limit}`;

    if (query.field && query.value) {
      formatedQuery += `&${query.field}=${query.value}`;
    }
    if (query.sort) {
      formatedQuery += `${query.sort}`;
    } else {
      formatedQuery += `&sort_field=createdAt&sort_direction=desc`;
    }
    if (query.state && query.state !== "Tous") {
      formatedQuery += `&state=${query.state}`;
    }

    return formatedQuery;
  }

  async function handleDelete(id?: string) {
    controller.abort();
    try {
      controller = new AbortController();
      if (
        window.confirm("Voulez-vous supprimer cette demande d'estimation ?")
      ) {
        await deleteEstimate(id, controller.signal);
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleQueryDateChange({ target }: { target: HTMLInputElement }) {
    target.name === "dateFrom"
      ? setQuery({
          ...query,
          dateFrom: `${target.value}`,
          dateTo: query.dateTo !== "" ? query.dateTo : `${target.value}`,
        })
      : setQuery({
          ...query,
          dateTo: `${target.value}`,
          dateFrom: query.dateFrom !== "" ? query.dateFrom : `${target.value}`,
        });
  }

  function handleQueryChange({
    target,
  }: {
    target: HTMLInputElement | HTMLSelectElement;
  }) {
    setQuery((prev) => ({ ...prev, [target.name]: target.value }));
  }

  function formatDate(date: string | undefined) {
    return date !== ""
      ? date && new Date(date).toISOString().substring(0, 10)
      : "";
  }
}

// <EstimateDocument
//   key={estimate._id}
//   estimate={estimate}
//   onDelete={handleDelete}
// />
