import style from "./item.module.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import getStatusColor from "../../../translation/statusColor";
import { IEstimate } from "../../../types/booking.type";
import { IProduct } from "../../../types/logistic.types";
import { Delete, Edit, Launch } from "@mui/icons-material";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import translate from "../../../translation";
import { useModalComponent } from "../../../context/modalComponent.context";
import EstimateModal from "../../../components/modals/estimateModal/estimateModal";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";

export default function Item({
  data,
  index,
  onDelete,
}: {
  data: IEstimate;
  index: number;
  onDelete: (id?: string) => void;
}) {
  const navigate = useNavigate();
  const [hover, setHover] = useState<Boolean>(false);
  const { setModalIsOpen, setModalChildren } = useModalComponent();

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: () => {
        openEstimateModal();
      },
    },
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/estimate/edit/${data.id}`,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      onClick: () => onDelete(data.id),
    },
  ];

  return (
    <div
      className={style["item"]}
      style={{
        backgroundColor: `${hover ? "#e4e4e7" : ""}`,
      }}
    >
      <div className={style["item-top"]}>
        <div className={`${style["item-element"]} ${style["item-coord"]}`}>
          <span className={style["displayname"]}>
            {data.contact.displayname}
          </span>
          <span>{data.contact.organization}</span>
          <span>{data.contact.phone}</span>
          <span>{data.contact.email}</span>
        </div>
        <div
          className={`${style["item-element"]} ${style["item-date"]}`}
          title={`${new Date(data?.createdAt).toLocaleString()}`}
        >
          {new Date(data?.createdAt).toLocaleDateString()}
        </div>
        <div
          className={`${style["item-element"]} ${style["item-product"]}`}
          onClick={() => {
            openEstimateModal();
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <div className={style["list"]}>
            {data?.productsList.map((value: IProduct, index: number) => {
              return (
                <div className={style["product-desc"]} key={index}>
                  <div className={style["brand"]}>{value.brand}</div>
                  <div className={style["brand"]}>
                    {value.operationsList?.length} operations
                  </div>
                  <div className={style["brand"]}>
                    {value?.operationsList?.reduce(
                      (accumulator, value) => accumulator + (value.price ?? 0),
                      0
                    )}
                    €
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={`${style["item-element"]} ${style["status-box"]}`}>
          <span
            className={style["status"]}
            style={{ backgroundColor: `${getStatusColor(data.state)}` }}
          >
            {translate(data.state)}
          </span>
        </div>
        <div className={`${style["item-element"]} ${style["tools"]}`}>
          <ProtectedComponent roles={["ROLE_ADMIN"]}>
            <MoreTools options={MORE_TOOLS_OPTIONS} zIndex={index} />
          </ProtectedComponent>
        </div>
      </div>
    </div>
  );

  function openEstimateModal() {
    if (data && data.id !== "") {
      setModalIsOpen(true);
      data.id && setModalChildren(<EstimateModal id={data.id} />);
    }
  }
}
