import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Widget from "../../../components/widget/widget";
import { loadQuote } from "../../../requests/tracker";
import translate from "../../../translation";
import { IProduct } from "../../../types/logistic.types";
import style from "./quoteEdit.module.scss";
import {
  IDiscount,
  IQuote,
  IShippingMethod,
} from "../../../types/accounting.types";
import { updateQuote } from "../../../requests/quote";
import { Check, Edit, LocalOfferOutlined, Send } from "@mui/icons-material";
import ContactInfoCard from "../../../components/contactInfoCard/contactInfoCard";
import { fetchShippingMethodsList } from "../../../requests/shippingMethod";
import { sortOperations } from "../../../utils/utils";
import { REFASHION_OPERATIONS_PRICE } from "../../../utils/operation.init";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../utils/toast.options";
import ConfirmModalComp from "../../../components/confirmationModal/ConfirmModalComp";
import { useConfirmModal } from "../../../context/confirmationModalContext";
import REFASHION_LOGO from "../../../assets/bonus-reparation-300x182.png";


import clsx from "clsx";

export default function QuoteEdit() {
  const { trackId } = useParams();
  const [quote, setQuote] = useState<IQuote | undefined>(undefined);
  const { setConfirmModalIsOpen, setConfirmModalChildren } = useConfirmModal();
  const { data, refetch } = useQuery(
    ["quote-edit", trackId],
    () => loadQuote({ params: { trackerId: trackId } }),
    { refetchOnWindowFocus: false }
  );
  const { data: shippingMethodsList } = useQuery(
    "quote-edit-discount",
    () => fetchShippingMethodsList(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setQuote(data);
    }
  }, [data]);

  function handleProductsListChange(products: IProduct[]) {
    if (!quote) {
      return;
    }
    setQuote({ ...quote, productsList: products });
  }

  return (
    <div className={style["quote-edit-container"]}>
      <ContactInfoCard contact={quote?.contact} trackId={data?.trackId} />
      <div className={style["main-title"]}>Devis</div>
      <div className={style["widgets-container"]}>
        <Widget className={style["widget-container"]}>
          {quote && (
            <ProductInformations
              onRefashionChange={handleProductsListChange}
              productsList={quote.productsList}
              operationsTotal={quote.operationsTotal}
            />
          )}
        </Widget>
        <div className={style["right-widgets"]}>
          <Widget className={style["small-widget-container"]}>
            <TimeLimit quote={quote} onChange={handleOnChange} />
          </Widget>
          <Widget className={style["small-widget-container"]}>
            <Discount discount={quote?.discount} />
          </Widget>
          <Widget className={style["small-widget-container"]}>
            {quote && (
              <ShippingMode
                quote={quote}
                setQuote={setQuote}
                shippingMethodsList={shippingMethodsList?.shippingMethodsList}
                handleUpdateDocument={handleUpdateDocument}
              />
            )}
          </Widget>
        </div>
      </div>
      <div className={style["bottom-widget"]}>
        <Widget className={style["total-price-widget"]}>
          <div className={style["total-price-widget-container"]}>
            <span className={style["title"]}>Total devis</span>
            <span className={style["title"]}>{quote?.total} €</span>
          </div>
        </Widget>
        <div className={style["send-button-container"]}>
          <button
            className={style["send-button"]}
            onClick={handleConfirmCreateDocument}
          >
            <Send />
          </button>
        </div>
      </div>
    </div>
  );

  async function handleConfirmCreateDocument() {
    if (!(quote?.minimumDurationDays && quote?.maximumDurationDays)) {
      return toast.error(
        "Veuillez entrer les délais pour le devis !",
        TOAST_ERROR_OPTIONS
      );
    }

    if (!window.confirm("Avant de continuer, veuillez-vous assurer d'avoir affecté les bonus réparation.")) {
      return;
    }

    setConfirmModalIsOpen(true);
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={style["validate-button"]}
        title={"Voulez-vous envoyer le devis ?"}
        confirmText="Oui"
        onConfirm={handleCreateDocument}
      />
    );
  }

  async function handleCreateDocument() {
    try {
      if (!quote) {
        return;
      }
      await updateQuote({ ...quote, state: "QUOTE_SENT" }, true, true);
      navigate("../");
    } catch (error: any) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }

  async function handleUpdateDocument() {
    try {
      if (!quote) {
        return;
      }
      await updateQuote(quote);
      refetch();
    } catch (error: any) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }

  function handleOnChange({ target }: { target: HTMLInputElement }) {
    if (quote) {
      const newQuote = { ...quote };
      newQuote[target.name as keyof IQuote] = parseInt(target.value);
      setQuote(newQuote);
    }
  }
}

interface ProductInformationsProps {
  productsList: IProduct[] | undefined;
  operationsTotal?: number;
  onRefashionChange?: (productsList: IProduct[]) => void;
}

export function ProductInformations({
  productsList,
  operationsTotal,
  onRefashionChange,
}: ProductInformationsProps) {
  function handleChange(
    productIndex: number,
    operationIndex: number,
    refashionOperation?: string,
    refashionDiscount?: number
  ) {
    if (!productsList) {
      return;
    }
    let products = [...productsList];
    products[productIndex].operationsList[operationIndex].refashionDiscount =
      refashionDiscount;
    products[productIndex].operationsList[operationIndex].refashionOperation =
      refashionOperation;
    onRefashionChange && onRefashionChange(products);
  }

  function getTotalRefashion(productsList: IProduct[]) {
    let total = productsList.reduce((accProd, curProd) => {
      return (
        accProd +
        curProd.operationsList.reduce((accOp, curOp) => {
          return accOp + (curOp.refashionDiscount || 0);
        }, 0)
      );
    }, 0);
    return total;
  }

  return (
    <div className={style["product-informations"]}>
      <div className={style["product-informations-container"]}>
        {productsList &&
          productsList.map((product: IProduct, productIndex: number) => {
            return (
              <div key={productIndex}>
                <div
                  className={`${style["widget-title"]} ${
                    allOperationsAreCanceled(product) &&
                    style["operation-canceled"]
                  }`}
                >
                  {translate(product.productGroup)} -{" "}
                  {translate(product.operationGroup)} -{" "}
                  {translate(product.brand)}
                  {product.workshopId && (
                    <div className={style["workshopId"]}>
                      {product.workshopId}
                      <LocalOfferOutlined className={style["tag-icon"]} />
                    </div>
                  )}
                </div>
                <ul>
                  {product.operationsList
                    .sort(sortOperations)
                    .map((operation, operationIndex) => {
                      return (
                        <li
                          key={operationIndex}
                          className={clsx(style["operation-list-item"], {
                            [style["operation-canceled"]]: operation.canceled,
                          })}
                        >
                          <div className={style["operation-line"]}>
                            <span className={style["operation-description"]}>
                              {operation.description}
                            </span>
                            <hr className={style["operation-dots"]} />
                            <div className={style["operation-price"]}>
                              {Math.floor((operation.price || 0) * 100) / 100} €
                            </div>
                          </div>
                          {onRefashionChange && (
                            <div className={style["operation-discount-line"]}>
                              <img className={style["discount-logo"]} src={REFASHION_LOGO}></img>
                                <select
                                  className={style["operation-discount-select"]}
                                  value={operation.refashionOperation}
                                  onChange={({ target }) => {
                                    let refashionDiscount =
                                      REFASHION_OPERATIONS_PRICE.find(
                                        (o) => o.name === target.value
                                      )?.amount || 0;
                                    let refashionOperation =
                                      REFASHION_OPERATIONS_PRICE.find(
                                        (o) => o.name === target.value
                                      )?.name || "N/A";

                                    handleChange(
                                      productIndex,
                                      operationIndex,
                                      refashionOperation,
                                      refashionDiscount
                                    );
                                  }}
                                >
                                  <option
                                    className={
                                      style["operation-discount-option"]
                                    }
                                  >
                                    N/A
                                  </option>
                                  {REFASHION_OPERATIONS_PRICE.map(
                                    (op, index) => (
                                      <option
                                        key={index}
                                        className={
                                          style["operation-discount-option"]
                                        }
                                        value={op.name}
                                      >
                                        {op.description} - {op.amount} €
                                      </option>
                                    )
                                  )}
                                </select>
                            </div>
                          )}
                        </li>
                      );
                    })}
                </ul>
              </div>
            );
          })}
      </div>
      <div className={style["product-informations-bottom"]}>
        <div className={style["product-informations-number"]}>
          Total refashion {getTotalRefashion(productsList ?? [])} €
        </div>
        <div className={style["product-informations-number"]}>
          Nombre d'articles {productsList?.length}
        </div>
        {operationsTotal && (
          <div className={style["product-informations-total"]}>
            <span>Total opérations</span>{" "}
            {Math.floor(operationsTotal * 100) / 100} €
          </div>
        )}
      </div>
    </div>
  );
  function allOperationsAreCanceled(product: IProduct): boolean {
    return product.operationsList.every((op) => op.canceled);
  }
}

interface TimeLimitProps {
  quote: IQuote | undefined;
  onChange: ({ target }: { target: HTMLInputElement }) => void;
}

function TimeLimit({ quote, onChange }: TimeLimitProps) {
  return (
    <div className={style["timelimit"]}>
      <div className={style["title"]}>Délais</div>
      <div className={style["timelimit-container"]}>
        <div className={style["timelimit-line"]}>
          Délai minimum
          <input
            name={"minimumDurationDays"}
            value={quote?.minimumDurationDays ?? 0}
            type="number"
            min={0}
            onChange={onChange}
          />
        </div>
        <div className={style["timelimit-line"]}>
          Délai maximum
          <input
            name={"maximumDurationDays"}
            value={quote?.maximumDurationDays ?? 0}
            type="number"
            min={0}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
}

function Discount({ discount }: { discount: IDiscount | null | undefined }) {
  return (
    <div className={style["discount-container"]}>
      <div className={style["title"]}>Codes Promos</div>
      <div className={style["table-container"]}>
        {discount ? (
          <table>
            <tbody>
              <tr>
                <th>{"Code"}</th>
                <th>{"Valeur"}</th>
              </tr>
              <tr>
                <td>{discount.code}</td>
                <td>
                  {Math.floor(discount.value * 100) / 100}{" "}
                  {discount.isRate ? "%" : "€"}
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div className={style["not-found-message"]}>aucun code promo</div>
        )}
      </div>
    </div>
  );
}

function ShippingMode({
  shippingMethodsList,
  quote,
  setQuote,
  handleUpdateDocument,
}: {
  shippingMethodsList: IShippingMethod[] | undefined;
  quote: any;
  setQuote: (quote: IQuote) => void;
  handleUpdateDocument: () => void;
}) {
  const [editShippingMethod, setEditShippingMethod] = useState<boolean>(false);
  const [shippingMethod, setShippingMethod] = useState<IShippingMethod>(
    quote.shipping
  );

  useEffect(() => {
    const newQuote = { ...quote };
    newQuote.shipping = shippingMethod;
    setQuote(newQuote);
  }, [shippingMethod]);

  function handleShippingMethodChange({
    target,
  }: {
    target: EventTarget & HTMLSelectElement;
  }) {
    const found = shippingMethodsList?.find(
      (shippingMethod: IShippingMethod) =>
        shippingMethod.shippingService === target.value
    );
    found && setShippingMethod(found);
  }

  function handlePriceChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    const newShippingMethod = { ...shippingMethod };
    if (target.value === "" || isNaN(parseInt(target.value))) {
      newShippingMethod.price = 0;
    } else {
      newShippingMethod.price = parseInt(target.value);
    }
    setShippingMethod(newShippingMethod);
  }

  function handleEditShipping() {
    setEditShippingMethod(!editShippingMethod);
    if (editShippingMethod) {
      handleUpdateDocument();
    }
  }

  return (
    <div className={style["discount-container"]}>
      <div className={style["title-section"]}>
        <div className={style["title"]}>
          Livraison
          {quote.operationsTotal > quote.shipping.freeShippingMinimumAmount &&
            ` (gratuite) > ${quote.shipping.freeShippingMinimumAmount}€`}
        </div>
        <button className={style["edit-button"]} onClick={handleEditShipping}>
          {editShippingMethod ? <Check /> : <Edit />}
        </button>
      </div>
      <div className={style["table-container"]}>
        {quote && quote.shipping ? (
          <table>
            <tbody>
              <tr>
                <th>{"Type"}</th>
                <th>{"Prix"}</th>
              </tr>
              {editShippingMethod ? (
                <tr>
                  <td>
                    <select
                      onChange={handleShippingMethodChange}
                      value={shippingMethod?.shippingService}
                    >
                      {shippingMethodsList &&
                        shippingMethodsList.map(
                          (shippingMethod: IShippingMethod, key: number) => {
                            return (
                              <option
                                key={key}
                                value={shippingMethod.shippingService}
                              >
                                {translate(shippingMethod.shippingService)}
                              </option>
                            );
                          }
                        )}
                    </select>
                  </td>
                  <td>
                    <input
                      className={style["shipping-price-input"]}
                      value={quote.shipping.price}
                      onChange={handlePriceChange}
                      inputMode="numeric"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleEditShipping();
                      }}
                    />
                    €
                  </td>
                </tr>
              ) : (
                <tr>
                  <td className={style["shipping-method-values"]}>
                    {translate(quote.shipping.shippingService)}
                  </td>
                  <td className={style["shipping-method-values"]}>
                    <span className={style["shipping-price-value"]}>
                      {quote.shipping.totalPrice +
                        (quote.shipping.internationalFees || 0)}
                    </span>
                    €
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <div className={style["not-found-message"]}>aucune livraison</div>
        )}
      </div>
    </div>
  );
}
