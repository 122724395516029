import { Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import SplitPage from "./components/splitPage/splitPage.component";
import style from "./App.module.scss";
import HideSideBarProvider, {
  useHideSideBar,
} from "./context/hideSideBar.context";
import WebCamCaptureProvider from "./context/webCamCapture.context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalComponentProvider from "./context/modalComponent.context";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { isDesktop } from "react-device-detect";
import { TouchBackend } from "react-dnd-touch-backend";
import ImageViewerProvider from "./context/imageViewer.context";
import CustomRouter from "./routes";
import AuthProvider from "./context/auth.context";
import ConfirmModalProvider from "./context/confirmationModalContext";

export function Layout() {
  const { hideSide } = useHideSideBar();

  return (
    <div className={style["layout"]}>
      <SplitPage>
        <></>
      </SplitPage>
      <div className={hideSide ? style["outlet-small"] : style["outlet"]}>
        <Outlet />
      </div>
    </div>
  );
}

export default function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <DndProvider backend={isDesktop ? HTML5Backend : TouchBackend}>
          <ImageViewerProvider>
            <ModalComponentProvider>
              <ConfirmModalProvider>
                <WebCamCaptureProvider>
                  <HideSideBarProvider>
                    <ToastContainer />
                    <CustomRouter />
                  </HideSideBarProvider>
                </WebCamCaptureProvider>
              </ConfirmModalProvider>
            </ModalComponentProvider>
          </ImageViewerProvider>
        </DndProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
