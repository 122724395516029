import { useEffect, useState } from "react";
import style from "./sideBar.module.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  Basket2,
  BriefcaseFill,
  CalculatorFill,
  PeopleFill,
} from "react-bootstrap-icons";
import SideBarList from "./sideBarList/sideBarList.component";
import SideBarMenu from "./subMenuDispatch/sideBarMenu.component";
import HubIcon from "@mui/icons-material/Hub";
import { ProtectedComponent } from "../protectedComponent/protectedComponent";
import {
  Architecture,
  BuildCircleOutlined,
  ExitToApp,
  RocketLaunch,
  Today,
} from "@mui/icons-material";
import { useHideSideBar } from "../../context/hideSideBar.context";
import SideBarHeader from "./sideBarComponents/header.component";
import { logoutRequest } from "../../requests/user";
import { useAuth } from "../../context/auth.context";
import { useConfirmModal } from "../../context/confirmationModalContext";
import ConfirmModalComp from "../confirmationModal/ConfirmModalComp";

export interface MenuItem {
  key: number;
  expand: boolean;
}

export default function SideBar() {
  const { setHideSide } = useHideSideBar();
  const navigate = useNavigate();
  const { unregisterUser } = useAuth();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const { setConfirmModalIsOpen, setConfirmModalChildren } = useConfirmModal();
  const [focused, setFocused] = useState<string>("quick-access");

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateWindowWidth);
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 1900) {
      setHideSide(true);
    }
  }, [windowWidth]);

  return (
    <div className={style["side"]}>
      <div className={style["top"]}>
        <SideBarHeader />
        <div className={`${style["container"]}`}>
          <div className={style["menu"]}>
            <ProtectedComponent roles={["ROLE_ADMIN", "ROLE_LOGISTICS"]}>
              <Link
                onClick={handleCategoryClicked}
                className={style["no-expand-button"]}
                to="/quick-access"
              >
                <SideBarList
                  title={"Accès rapide"}
                  Logo={RocketLaunch}
                  focused={focused}
                  setFocused={setFocused}
                />
              </Link>
            </ProtectedComponent>
            <ProtectedComponent roles={["ROLE_ADMIN", "ROLE_LOGISTICS"]}>
              <Link
                onClick={handleCategoryClicked}
                className={style["no-expand-button"]}
                to="/overview"
              >
                <SideBarList
                  title={"Vue d'ensemble"}
                  Logo={HubIcon}
                  focused={focused}
                  setFocused={setFocused}
                />
              </Link>
            </ProtectedComponent>
            <ProtectedComponent
              roles={["ROLE_ADMIN", "ROLE_LOGISTICS", "ROLE_SHIPPING"]}
            >
              <Link
                onClick={handleCategoryClicked}
                className={style["no-expand-button"]}
                to="/shipping"
              >
                <SideBarList
                  title={"Calendrier de livraison"}
                  Logo={Today}
                  focused={focused}
                  setFocused={setFocused}
                />
              </Link>
            </ProtectedComponent>
            <ProtectedComponent roles={["ROLE_ADMIN", "ROLE_LOGISTICS"]}>
              <Link
                onClick={handleCategoryClicked}
                className={style["no-expand-button"]}
                to="/logistic"
              >
                <SideBarList
                  title={"Logistique"}
                  Logo={Architecture}
                  focused={focused}
                  setFocused={setFocused}
                />
              </Link>
            </ProtectedComponent>
            <ProtectedComponent roles={["ROLE_ADMIN"]}>
              <SideBarList
                title={"Professionels"}
                Logo={BriefcaseFill}
                focused={focused}
                setFocused={setFocused}
                expandMenu
              >
                <div className={style["item-list"]}>
                  <SideBarMenu
                    handleLinkClicked={handleLinkClicked}
                    to={"/licenses"}
                    title={"Licences"}
                  />
                  <SideBarMenu
                    handleLinkClicked={handleLinkClicked}
                    to={"/shops"}
                    title={"Boutiques"}
                  />
                  <SideBarMenu
                    handleLinkClicked={handleLinkClicked}
                    to={`/professionalCarts`}
                    title={"Demandes"}
                  />
                  <SideBarMenu
                    handleLinkClicked={handleLinkClicked}
                    to={`/invoice-pro`}
                    title={"Factures"}
                  />
                </div>
              </SideBarList>
            </ProtectedComponent>
            <ProtectedComponent roles={["ROLE_ADMIN"]}>
              <SideBarList
                title={"Clients"}
                Logo={Basket2}
                focused={focused}
                setFocused={setFocused}
                expandMenu
              >
                <div>
                  <div className={style["item-list"]}>
                    <SideBarMenu
                      handleLinkClicked={handleLinkClicked}
                      to={"/customers"}
                      title={"Comptes clients"}
                    />
                    <SideBarMenu
                      handleLinkClicked={handleLinkClicked}
                      to={"/carts"}
                      title={"Commandes"}
                    />
                    <SideBarMenu
                      handleLinkClicked={handleLinkClicked}
                      to={"/estimate"}
                      title={"Demandes d'estimation"}
                    />
                  </div>
                </div>
              </SideBarList>
            </ProtectedComponent>
            <ProtectedComponent roles={["ROLE_ADMIN"]}>
              <SideBarList
                title={"Comptabilité"}
                Logo={CalculatorFill}
                focused={focused}
                setFocused={setFocused}
                expandMenu
              >
                <div>
                  <div className={style["item-list"]}>
                    <SideBarMenu
                      handleLinkClicked={handleLinkClicked}
                      to={"/operation"}
                      title={"Opérations"}
                    />
                    <SideBarMenu
                      handleLinkClicked={handleLinkClicked}
                      to={"/operation-pro"}
                      title={"Opérations B2B"}
                    />
                    <SideBarMenu
                      handleLinkClicked={handleLinkClicked}
                      to={"/cross-selling"}
                      title={"Cross-selling"}
                    />
                    <SideBarMenu
                      handleLinkClicked={handleLinkClicked}
                      to={"/shipping-method"}
                      title={"Méthodes de livraison"}
                    />
                    <SideBarMenu
                      handleLinkClicked={handleLinkClicked}
                      to={"/discount"}
                      title={"Codes Promos"}
                    />
                    <SideBarMenu
                      handleLinkClicked={handleLinkClicked}
                      to={"/quotes"}
                      title={"Devis"}
                    />
                    <SideBarMenu
                      handleLinkClicked={handleLinkClicked}
                      to={"/payments"}
                      title={"Paiements"}
                    />
                  </div>
                </div>
              </SideBarList>
            </ProtectedComponent>
            <ProtectedComponent roles={["ROLE_ADMIN"]}>
              <Link
                onClick={handleCategoryClicked}
                className={style["no-expand-button"]}
                to="/users"
              >
                <SideBarList
                  title={"Gestion d'utilisateurs"}
                  Logo={PeopleFill}
                  focused={focused}
                  setFocused={setFocused}
                />
              </Link>
            </ProtectedComponent>
            <ProtectedComponent roles={["ROLE_DEVELOPER"]}>
              <Link
                onClick={handleCategoryClicked}
                className={style["no-expand-button"]}
                to="/dev-tools"
              >
                <SideBarList
                  title={"DEV_TOOLS"}
                  Logo={BuildCircleOutlined}
                  focused={focused}
                  setFocused={setFocused}
                />
              </Link>
            </ProtectedComponent>
            {windowWidth <= 1400 && (
              <div
                onClick={handleConfirmLogout}
                className={style["responsive-logout-button"]}
              >
                <SideBarList
                  title={"Déconnexion"}
                  Logo={ExitToApp}
                  focused={focused}
                  setFocused={setFocused}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  function handleLinkClicked() {
    if (windowWidth <= 1850) {
      setHideSide(true);
    }
  }

  function handleCategoryClicked() {
    if (windowWidth <= 1850) {
      setHideSide(true);
    }
  }

  function handleConfirmLogout() {
    setConfirmModalIsOpen(true);
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={style["delete-button"]}
        title={"Voulez-vous vous déconnecter?"}
        confirmText="Se déconnecter"
        onConfirm={handlelogout}
      />
    );
  }

  function handlelogout() {
    logoutRequest();
    unregisterUser();
    navigate("/login");
  }
}
