import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../../pages/error-page";
import Create from "./create/create";
import { authloader } from "..";
import Carts from ".";
import Edit from "./edit/edit";

export const route: RouteObject = {
  path: "/carts",
  element: <Outlet />,
  loader: authloader,
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <Carts />,
      loader: authloader,
    },
    {
      path: "create",
      element: <Create />,
      loader: authloader,
    },
    {
      path: "edit/:id",
      element: <Edit />,
      loader: authloader,
    },
  ],
};
