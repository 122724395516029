import { cloneElement, useState } from "react";
import style from "./moreTools.module.scss";
import { MoreVert } from "@mui/icons-material";
import useClickOutside from "../../hooks/useClickOutSide";
import useElementDimensions from "../../hooks/useElementDimensions";
import { Link } from "react-router-dom";

export interface IMoreToolsOptions {
  icon?: JSX.Element;
  name: string;
  onClick?: () => void;
  to?: string;
}

interface MoreToolsProps {
  zIndex?: number;
  options: IMoreToolsOptions[];
}

export default function MoreTools({ zIndex, options }: MoreToolsProps) {
  const [expand, setExpand] = useState<boolean>(false);
  const ref = useClickOutside<HTMLDivElement>(handleCloseDropDown);
  const [moreButtonRef, moreButtonWidth] = useElementDimensions<HTMLDivElement>(
    [expand]
  );
  const [dropdownRef, dropdownWidth] = useElementDimensions<HTMLDivElement>([
    expand,
  ]);
  const DROPDOWN_JUSTIFY_LEFT = {
    transform: `translateX(calc(${moreButtonWidth}px - ${dropdownWidth}px))`,
  };

  return (
    <div
      className={style["more-tools"]}
      ref={ref}
      style={{ zIndex: `${zIndex || 10}` }}
    >
      <div className={style["more-tools-container"]}>
        <div
          className={style["more-button"]}
          onClick={handleExpand}
          ref={moreButtonRef}
        >
          <MoreVert />
        </div>
        <div
          className={style["more-tools-dropdown-wrapper"]}
          style={DROPDOWN_JUSTIFY_LEFT}
        >
          {expand && (
            <div className={style["more-tools-dropdown"]} ref={dropdownRef}>
              <div className={style["option-list"]}>
                {options.map((option, index: number) => {
                  const styledIcon: JSX.Element = cloneElement(
                    option.icon || <span></span>,
                    {
                      className: style["option-list-item-icon"],
                    }
                  );
                  return option.to ? (
                    <Link
                      className={style["option-list-item"]}
                      key={index}
                      onClick={() => handleClickOnOption(option.onClick)}
                      to={option.to}
                    >
                      {styledIcon}
                      {option.name}
                    </Link>
                  ) : (
                    <div
                      className={style["option-list-item"]}
                      key={index}
                      onClick={() => handleClickOnOption(option.onClick)}
                    >
                      {styledIcon}
                      {option.name}
                    </div>
                  );
                })}
              </div>
              <div className={style["beautiful-div"]}></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  function handleClickOnOption(fct?: () => void) {
    fct && fct();
    setExpand(false);
  }

  function handleExpand() {
    setExpand(!expand);
  }

  function handleCloseDropDown() {
    setExpand(false);
  }
}
