import { useQuery } from "react-query";
import style from "./cartModal.module.scss";
import { fetchCart } from "../../../requests/carts";
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";
import ContactInfoCard from "../../contactInfoCard/contactInfoCard";
import { Link } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { StatusItem } from "../../statusItem/statusItem";
import { useContext } from "react";
import { useModalComponent } from "../../../context/modalComponent.context";
import { ProductInformations } from "../../../routes/quickAccess/quoteEdit/quoteEdit";
import { IProduct } from "../../../types/logistic.types";
import { IOperation } from "../../../types/accounting.types";

interface CartModalProps {
  id: string;
}

export default function CartModal({ id }: CartModalProps) {
  const { data, isFetching, error } = useQuery("cart-modal", () =>
    fetchCart(id)
  );
  const { setModalIsOpen } = useModalComponent();

  return (
    <div className={style["cart-modal"]}>
      {!isFetching ? (
        <>
          {data && (
            <div className={style["read-document"]}>
              <ContactInfoCard
                contact={data?.contact}
                className={style["cart-modal-contact-card"]}
                trackId={data?.trackId}
              />
              <div className={style["header"]}>
                <div className={style["header-left"]}>Panier</div>
                <div className={style["header-right"]}>
                  <Link
                    onClick={handleEdit}
                    to={`/carts/edit/${data.id}`}
                    className={style["tools-icon"]}
                  >
                    <Edit className={style["edit-icon"]} />
                  </Link>
                  <div className={style["state-container"]}>
                    <StatusItem
                      data={{ state: data.state, documentId: data.id }}
                    />
                  </div>
                </div>
              </div>
              <div className={style["body"]}>
                <div className={style["product-information-container"]}>
                  <ProductInformations
                    productsList={data.productsList}
                    operationsTotal={cartTotal()}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className={style["loading-container"]}>
          <LoadingSpinner color="gold" />
        </div>
      )}
    </div>
  );

  function handleEdit() {
    window.scrollTo(0, 0);
    setModalIsOpen(false);
  }

  function cartTotal() {
    if (!data) {
      return;
    }
    return data.productsList.reduce((acc, product: IProduct) => {
      return (
        acc +
        product.operationsList.reduce(
          (acc, operation: IOperation) => acc + (operation.price || 0),
          0
        )
      );
    }, 0);
  }
}
