import style from "./read.module.scss";
import { useQuery } from "react-query";
import { fetchShippingList } from "../../../requests/shipping";
import { useState } from "react";
import { Link } from "react-router-dom";
import { IShipping } from "../../../types/shipping.types";
import Item from "./listItem/item";
import { Replay } from "@mui/icons-material";
import { STATE_OPTIONS } from "../../../utils/shipping.init";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import PagingComponent from "../../../components/pagingComponent/pagingComponent";
import ListHeaderComponent from "../../../components/listHeaderComponent/listHeaderComponent";
import FilterByStatus from "../../../components/filterByStatus/filterByStatus";
import { queryObject } from "../../../types/query.types";
import SearchByKeyComponent from "../../../components/searchByKeyComponent/searchByKeyComponent";
import useSaveQueryStorage, {
  loadQueryStorage,
} from "../../../hooks/useSaveQueryStorage";
import Button from "../../../components/Button/Button";

const INITIAL_SHIPPING_QUERY: queryObject = {
  service: "",
  direction: "",
  state: "",
  limit: 10,
  offset: 0,
  sort: "",
  dateFrom: "",
  dateTo: "",
  field: "contact.displayname",
};

const SEARCH_OPTIONS = [
  "contact.displayname",
  "contact.phone",
  "contact.email",
];

export default function Read() {
  const [query, setQuery] = useState<queryObject>(
    loadQueryStorage(INITIAL_SHIPPING_QUERY, "shipping")
  );
  const { data, isFetching } = useQuery(
    ["shippingListData", query],
    () => fetchShippingList(formatedQuery()),
    { keepPreviousData: true }
  );
  useSaveQueryStorage(query, "shipping");

  if (isFetching) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["container"]}>
      <div className={style["item-list"]}>
        <div className={style["top"]}>
          <span className={style["title"]}>Livraisons</span>
          <ProtectedComponent roles={["ROLE_ADMIN"]}>
            <Link onClick={() => window.scrollTo(0, 0)} to={"../create"}>
              <Button>+ Ajouter nouveau</Button>
            </Link>
          </ProtectedComponent>
        </div>
        <div className={style["filters-group"]}>
          <SearchByKeyComponent
            query={query}
            setQuery={setQuery}
            optionsList={SEARCH_OPTIONS}
          />
          <FilterByStatus
            query={query}
            setQuery={setQuery}
            optionsList={STATE_OPTIONS}
            field={"status"}
            className={style["filter-by-status"]}
          />
        </div>
        <div className={style["filters-container"]}>
          <div className={style["filters-group"]}>
            <div className={style["filters"]}>
              <div className={style["shipping-service"]}>
                <label>Service</label>
                <div className={style["shipping-service-item"]}>
                  <button
                    name={"shippingService"}
                    value={"chrono"}
                    className={`${style["shipping-service-button"]} ${
                      query.service === "CHRONOPOST-2SHOP" && style["toggled"]
                    }`}
                    onClick={() =>
                      setQuery((prev) => ({
                        ...prev,
                        service: "CHRONOPOST-2SHOP",
                      }))
                    }
                  >
                    Chronopost
                  </button>
                  <button
                    name={"shippingService"}
                    value={"gp"}
                    className={`${style["shipping-service-button"]} ${
                      query.service === "INTERNAL" && style["toggled"]
                    }`}
                    onClick={() =>
                      setQuery((prev) => ({ ...prev, service: "INTERNAL" }))
                    }
                  >
                    Local
                  </button>
                  <button
                    name={"shippingService"}
                    value={"all"}
                    className={`${style["shipping-service-button"]} ${
                      query.service === "" && style["toggled"]
                    }`}
                    onClick={() =>
                      setQuery((prev) => ({ ...prev, service: "" }))
                    }
                  >
                    Tous
                  </button>
                </div>
              </div>
              <div className={style["shipping-direction"]}>
                <label>Direction</label>
                <div className={style["shipping-service-item"]}>
                  <button
                    name={"direction"}
                    value={"OUTGOING"}
                    className={`${style["shipping-service-button"]} ${
                      query.direction === "OUTGOING" && style["toggled"]
                    }`}
                    onClick={() =>
                      setQuery((prev) => ({ ...prev, direction: "OUTGOING" }))
                    }
                  >
                    Livraison
                  </button>
                  <button
                    name={"direction"}
                    value={"INCOMING"}
                    className={`${style["shipping-service-button"]} ${
                      query.direction === "INCOMING" && style["toggled"]
                    }`}
                    onClick={() =>
                      setQuery((prev) => ({ ...prev, direction: "INCOMING" }))
                    }
                  >
                    Collecte
                  </button>
                  <button
                    name={"direction"}
                    value={"ALL"}
                    className={`${style["shipping-service-button"]} ${
                      query.direction === "" && style["toggled"]
                    }`}
                    onClick={() =>
                      setQuery((prev) => ({ ...prev, direction: "" }))
                    }
                  >
                    Tous
                  </button>
                </div>
              </div>
            </div>
            <div className={style["shipping-date"]}>
              <label>Du</label>
              <input
                name={"dateFrom"}
                type="date"
                lang="FR"
                max={formatDate(query.dateTo)}
                value={formatDate(query.dateFrom)}
                onChange={handleQueryDateChange}
                className={style["date-input"]}
              />
            </div>
            <div className={style["shipping-date"]}>
              <label>Au</label>
              <input
                name={"dateTo"}
                type="date"
                lang="FR"
                min={formatDate(query.dateFrom)}
                value={formatDate(query.dateTo)}
                onChange={handleQueryDateChange}
                className={style["date-input"]}
              />
            </div>
          </div>
          {query !== INITIAL_SHIPPING_QUERY && (
            <div
              className={style["reset-filters"]}
              onClick={() => setQuery(INITIAL_SHIPPING_QUERY)}
              title="réinitialiser"
            >
              <Replay />
            </div>
          )}
        </div>

        <div className={style["list-header"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
          <div className={style["header"]}>
            <ListHeaderComponent
              name={"period.start"}
              title="Crénaux de livraison"
              headerClassName={style["header-element-container"]}
              query={query}
              setQuery={setQuery}
            />
            <ListHeaderComponent
              name={"contact.givenname"}
              title="Informations livraison"
              headerClassName={style["header-element-container"]}
              query={query}
              setQuery={setQuery}
            />
            <div className={style["header-element-container"]}>
              Nbr d'articles
            </div>
            <ListHeaderComponent
              name={"state"}
              title="Status"
              headerClassName={style["header-element-container"]}
              query={query}
              setQuery={setQuery}
            />
            <div className={style["header-element-container"]}>Actions</div>
          </div>
          <div className={style["list-body"]}>
            {data && data.shippingsList.length > 0 ? (
              data.shippingsList.map((shipping: IShipping, key: number) => {
                return <Item data={shipping} key={key} />;
              })
            ) : (
              <div className={style["empty-list"]}>aucun element trouvé</div>
            )}
          </div>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
      </div>
    </div>
  );

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery += `?limit=${query.limit}`;
    }

    formatedQuery += `&offset=${query.offset * query.limit}`;

    if (query.field && query.value) {
      formatedQuery += `&${query.field}=${query.value}`;
    }
    if (query.sort) {
      formatedQuery += `${query.sort}`;
    }

    if (query.state && query.state !== "Tous") {
      formatedQuery += `&state=${query.state}`;
    }

    if (query.direction) {
      formatedQuery += `&direction=${query.direction}`;
    }

    if (query.service) {
      formatedQuery += `&shippingMethod.shippingService=${query.service}`;
    }

    if (!query.sort) {
      formatedQuery += `&sort_field=period.start&sort_direction=desc`;
    }

    formatedQuery += formatDateQuery();
    return formatedQuery;
  }

  function formatDateQuery(): string {
    if (query.dateFrom === "" && query.dateTo === "") return "";

    return (
      "&period.start=" +
      query.dateFrom +
      "T00:00:00.000Z" +
      "&period.start=" +
      query.dateTo +
      "T00:00:00.000Z"
    );
  }

  function queryHandler(target: EventTarget & HTMLButtonElement) {
    switch (target.name) {
      case "shippingService":
        return toggleShippingService(target.value);
      case "direction":
        return toggleShippingDirection(target.value);
      default:
        return handleStateFilter(target.value);
    }
  }

  function toggleShippingService(type: string) {
    const newQuery = { ...query };

    switch (type) {
      case "chrono":
        return setQuery({
          ...newQuery,
          service: "CHRONOPOST-2SHOP",
        });
      case "gp":
        return setQuery({ ...newQuery, service: "GP" });
      default:
        return setQuery({ ...newQuery, service: "" });
    }
  }

  function toggleShippingDirection(direction: string) {
    const newQuery = { ...query };

    switch (direction) {
      case "INCOMING":
        return setQuery({
          ...newQuery,
          direction: "&direction=INCOMING",
        });
      case "OUTGOING":
        return setQuery({
          ...newQuery,
          direction: "&direction=OUTGOING",
        });
      default:
        return setQuery({ ...newQuery, direction: "" });
    }
  }

  function handleStateFilter(value: string) {
    const newQuery = { ...query };

    if (value !== "Tous") {
      return setQuery({ ...newQuery, state: value });
    }
    return setQuery({ ...newQuery, state: "" });
  }

  function formatDate(date: string | undefined) {
    return date !== ""
      ? date && new Date(date).toISOString().substring(0, 10)
      : "";
  }

  function handleQueryDateChange({ target }: { target: HTMLInputElement }) {
    target.name === "dateFrom"
      ? setQuery({
          ...query,
          dateFrom: `${target.value}`,
          dateTo: query.dateTo !== "" ? query.dateTo : `${target.value}`,
        })
      : setQuery({
          ...query,
          dateTo: `${target.value}`,
          dateFrom: query.dateFrom !== "" ? query.dateFrom : `${target.value}`,
        });
  }
}
