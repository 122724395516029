import { useEffect, useState } from "react";
import style from "./index.module.scss";
import Item from "./listItem/item";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import { fetchTrackersList } from "../../requests/tracker";
import { useQuery } from "react-query";
import { TRACKING_STATE } from "../../utils/tracking.init";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import FilterByStatus from "../../components/filterByStatus/filterByStatus";
import { queryObject } from "../../types/query.types";
import useSaveQueryStorage, {
  loadQueryStorage,
} from "../../hooks/useSaveQueryStorage";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";

const OPTIONS_LIST = [
  "logistic.workshopIds",
  "contact.displayname",
  "contact.organization",
  "contact.phone",
  "contact.email",
];

const INITIAL_OVERVIEW_QUERY: queryObject = {
  field: "logistic.workshopIds",
  value: "",
  direction: "DESC",
  state: "",
  limit: 10,
  offset: 0,
  sort: "sort_field=createdAt&sort_direction=desc",
};

export default function Overview() {
  const [query, setQuery] = useState<queryObject>(
    loadQueryStorage(INITIAL_OVERVIEW_QUERY, "overview")
  );
  const { data, isLoading } = useQuery(
    ["overviewList", query],
    () => fetchTrackersList(formatedQuery()),
    { keepPreviousData: true }
  );
  useSaveQueryStorage(query, "overview");

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["overview-index-container"]}>
      <div className={style["top"]}>
        <span className={style["title"]}>Vue d'ensemble</span>
      </div>
      <div className={style["filters-container"]}>
        <div className={style["filters"]}>
          <SearchByKeyComponent
            query={query}
            setQuery={setQuery}
            optionsList={OPTIONS_LIST}
          />
          <FilterByStatus
            query={query}
            setQuery={setQuery}
            optionsList={TRACKING_STATE}
            isTracking
          />
        </div>
      </div>
      <div className={style["bottom"]}>
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
        <div className={style["header"]}>
          <div className={style["header-element-container"]}>Date</div>
          <div className={style["header-element-container"]}>Nom complet</div>
          <div className={style["header-element-container"]}>Estimation</div>
          <div className={style["header-element-container"]}>Panier</div>
          <div className={style["header-element-container"]}>Collecte</div>
          <div className={style["header-element-container"]}>Logistique</div>
          <div className={style["header-element-container"]}>Devis</div>
          <div className={style["header-element-container"]}>Livraison</div>
          <div className={style["header-element-container"]}>Général</div>
        </div>
        {data &&
          data.trackersList &&
          data.trackersList.map((value: any, index: number) => {
            return <Item data={value} key={index} />;
          })}
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
      </div>
    </div>
  );

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery = formatedQuery + `?limit=${query.limit}`;
    }

    formatedQuery = formatedQuery + `&offset=${query.offset * query.limit}`;

    if (query.field && query.value) {
      formatedQuery = formatedQuery + `&${query.field}=${query.value}`;
    }
    if (query.sort) {
      formatedQuery = formatedQuery + `&${query.sort}`;
    }

    if (query.state && query.state !== "Tous") {
      formatedQuery = formatedQuery + `&state=${query.state}`;
    }

    return formatedQuery;
  }
}
