import { ReactNode } from "react";
import style from "./widget.module.scss";
import { Link } from "react-router-dom";

interface WidgetProps {
  children?: ReactNode;
  to?: string;
  selected?: boolean;
  className?: string;
}

export default function Widget({
  children,
  to,
  selected,
  className,
}: WidgetProps) {
  if (to) {
    return (
      <Link
        to={to}
        className={`${style["widget-container-link"]} ${className}`}
      >
        {children}
      </Link>
    );
  }
  return (
    <div
      className={`${style["widget-container"]} ${className} ${
        selected ? style["selected"] : ""
      }`}
    >
      {children}
    </div>
  );
}
