import ClimbingWidget from "../../components/widget/climbing/climbing";
import ChronopostWidget from "../../components/widget/createChronopost/createChronopost";
import IncomingShippingWidget from "../../components/widget/incomingShippingWidget/incomingShippingWidget";
import LogisticWidget from "../../components/widget/logisticWidget/logisticWidget";
import ProShipping from "../../components/widget/outgoingShipping/proShipping";
import QualityControl from "../../components/widget/qualityControl/qualityControl";
import QuoteWidget from "../../components/widget/quoteWidget/quoteWidget";
import SearchProductWidget from "../../components/widget/searchProduct/searchProduct";
import WorkshopWidget from "../../components/widget/workshop/workshop";
import style from "./index.module.scss";

export default function QuickAccess() {
  return (
    <div className={style["quick-access"]}>
      <div className={style["quick-access-grid"]}>
        <SearchProductWidget />
        <IncomingShippingWidget />
        <LogisticWidget />
        <QualityControl />
        <WorkshopWidget />
        <QuoteWidget />
        <ProShipping />
        <ChronopostWidget />
      </div>
    </div>
  );
}
