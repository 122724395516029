import React from "react";
import style from "./loadingSpinner.module.scss";

export default function LoadingSpinner({
  color,
  className,
}: {
  color: string;
  className?: string;
}) {
  return (
    <div className={style["loading-spinner"]}>
      <div className={`${style.load} ${color}`}>
        <div className={`${style["line"]} ${className ? className : ""}`}></div>
        <div className={`${style["line"]} ${className ? className : ""}`}></div>
        <div className={`${style["line"]} ${className ? className : ""}`}></div>
      </div>
    </div>
  );
}
