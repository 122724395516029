import { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Outlet } from "react-router-dom";
// import Detail from "../../components/splitPage/detail.component";
import style from "./index.module.scss";
import { useQuery } from "react-query";
import { deleteCart, getCartsList } from "../../requests/professionalCarts";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import ListHeaderComponent from "../../components/listHeaderComponent/listHeaderComponent";
import Item from "./item/item";
import translate from "../../translation";
import { Replay } from "@mui/icons-material";
import { CART_STATE } from "../../utils/booking.init";
import { queryObject } from "../../types/query.types";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import FilterByStatus from "../../components/filterByStatus/filterByStatus";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import SelectByListAndInput from "../../components/searchByListAndInput/searchByListAndInput";
import { getShopsList } from "../../requests/shops";
import { IShop } from "../../types/shop.types";
import SelectAndSearchShop from "../../components/SelectAndSearchShop/SelectAndSearchShop";
import useSaveQueryStorage, {
  loadQueryStorage,
} from "../../hooks/useSaveQueryStorage";
import Button from "../../components/Button/Button";

export const INITIAL_QUERY: queryObject = {
  field: "productsList.workshopId",
  value: "",
  service: "",
  direction: "",
  status: "Tous",
  limit: 10,
  offset: 0,
  sort: "",
  dateFrom: "",
  dateTo: "",
  state: "",
  shopId: "",
};

const CART_SEARCH_OPTIONS = [
  "productsList.workshopId",
  "contact.displayname",
  "contact.phone",
  "contact.organization",
  "contact.email",
];
let controller = new AbortController();

export default function ProfessionalCarts() {
  const [searchParams] = useSearchParams();
  const SHOP_ID = searchParams.get("shopId");
  const shopName = searchParams.get("shopName");
  const [query, setQuery] = useState<queryObject>(
    loadQueryStorage(INITIAL_QUERY, "pro-cart")
  );
  const [shop, setShop] = useState<IShop | null>(null);
  const { data, isLoading, error, refetch } = useQuery(
    ["professionalCart", query, SHOP_ID, shop],
    () => getCartsList(formatedQuery()),
    { keepPreviousData: true }
  );
  useSaveQueryStorage(query, "pro-cart");

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["item-list"]}>
      <div className={style["top"]}>
        <div>
          {shopName ? (
            <span className={style["title"]}>Demandes Pro {shopName}</span>
          ) : (
            <span className={style["title"]}>Demandes Pro</span>
          )}
        </div>
        {SHOP_ID && (
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to={`./create/${SHOP_ID}`}
          >
            <Button>+ Ajouter nouveau</Button>
          </Link>
        )}
      </div>
      <div className={style["filters-container"]}>
        <div className={style["filters"]}>
          <SelectAndSearchShop shop={shop} setShop={setShop} />
          <SearchByKeyComponent
            query={query}
            setQuery={setQuery}
            optionsList={CART_SEARCH_OPTIONS}
          />
          <FilterByStatus
            query={query}
            setQuery={setQuery}
            optionsList={CART_STATE}
            field={"state"}
          />
        </div>
      </div>
      <div className={style["bottom"]}>
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
        <div className={style["item"]}>
          <div className={style["item-left"]}>
            <div className={style["item-element"]}>Coordonnées</div>
            <div className={style["item-element"]}>Date de création</div>
            <div className={style["item-element"]}>Articles</div>
            <div className={style["item-element"]}>Status</div>
          </div>
          <div className={style["item-element"]}>Actions</div>
        </div>
        {data &&
          data.cartsList &&
          data.cartsList.map((value: any, index: number) => {
            return (
              <Item
                key={index}
                data={value}
                onDelete={handleDelete}
                zIndex={query.limit - index}
              />
            );
          })}
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
      </div>
    </div>
  );

  async function handleDelete(id?: string) {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette commande ?")) {
      controller.abort();
      try {
        controller = new AbortController();
        await deleteCart(id, controller.signal);
        refetch();
      } catch (error) {
        console.error(error);
      }
    }
  }

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery += `?limit=${query.limit}`;
    }

    formatedQuery += `&offset=${query.offset * query.limit}`;

    if (query.field && query.value) {
      formatedQuery += `&${query.field}=${query.value}`;
    }

    if (query.state && query.state !== "Tous") {
      formatedQuery += `&state=${query.state}`;
    }
    if (SHOP_ID) {
      formatedQuery += `&shopId=${SHOP_ID}`;
    } else if (shop) {
      formatedQuery += `&shopId=${shop.id}`;
    }

    if (!(query.dateFrom === "" && query.dateTo === "")) {
      formatedQuery =
        formatedQuery +
        `&createdAt=` +
        query.dateFrom +
        `&createdAt=` +
        query.dateTo;
    }

    formatedQuery += `&sort_field=createdAt&sort_direction=desc`;

    return formatedQuery;
  }
}
