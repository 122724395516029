export async function streamFileMutation({filename, fileContent}: {filename: string, fileContent: File}) {
  let chunkSize = 1 * 512 * 524;
  let chunks = [];
  let fileSize = fileContent.size;
  let fileType = fileContent.type;
  let start = 0;
  let end = chunkSize;

  while (start < fileSize) {
    chunks.push(fileContent.slice(start, end));
    start = end;
    end = start + chunkSize;
  }

  let uploaded = 0;
  filename = Date.now().toString() + filename;
  for (let chunkIndex = 0; chunkIndex < chunks.length; chunkIndex++) {
    const chunk = chunks[chunkIndex];
    try {
      let response = await uploadFile(
        `${filename}`,
        chunk,
        fileType,
        fileSize,
        uploaded
      );
      if (response && response.message) {
        return response;
      } else if (response && response.uploaded) {
        uploaded = response.uploaded;
      }
    } catch (error: any) {
      throw new Response(JSON.stringify(error.body || {}), {
        statusText: error.statusText || error.message || error.toString(),
        status: error.status || 0,
      });
    }
  }
}

export async function streamFile(filename: string, fileContent: File) {
  let chunkSize = 1 * 512 * 524;
  let chunks = [];
  let fileSize = fileContent.size;
  let fileType = fileContent.type;
  let start = 0;
  let end = chunkSize;

  while (start < fileSize) {
    chunks.push(fileContent.slice(start, end));
    start = end;
    end = start + chunkSize;
  }

  let uploaded = 0;
  filename = Date.now().toString() + filename;
  for (let chunkIndex = 0; chunkIndex < chunks.length; chunkIndex++) {
    const chunk = chunks[chunkIndex];
    try {
      let response = await uploadFile(
        `${filename}`,
        chunk,
        fileType,
        fileSize,
        uploaded
      );
      if (response && response.message) {
        return response;
      } else if (response && response.uploaded) {
        uploaded = response.uploaded;
      }
    } catch (error: any) {
      throw new Response(JSON.stringify(error.body || {}), {
        statusText: error.statusText || error.message || error.toString(),
        status: error.status || 0,
      });
    }
  }
}

export async function uploadFile(
  fileName: string,
  chunk: Blob,
  fileType: string,
  fileSize: number,
  uploaded: number = 0
) {
  const formData = new FormData();
  formData.append("size", fileSize.toString());
  formData.append("uploaded", uploaded.toString());
  formData.append("type", fileType);
  formData.append("file", chunk, `${fileName}`);

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/file/uploadFile/${fileName}`,
      {
        method: "POST",
        credentials: "include",
        body: formData,
      }
    );

    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getFile(
  // request: Request,
  fileName: string
) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/file/${fileName}`,
      {
        // signal: request.signal,
        credentials: "include",
      }
    );
    // const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(response), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return response;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function deleteFile(
  // request: Request,
  fileName: string
) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/file/${fileName}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );

    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(response), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
