import {
  SaveOutlined,
  AddCircleOutline,
  Delete,
  LocalOfferOutlined,
} from "@mui/icons-material";
import {
  IContact,
  ILogistic,
  IProduct,
} from "../../../../../types/logistic.types";

import HANDBAG_DEFAULT from "../../../../../assets/maro.webp";
import SNEAKERS_DEFAULT from "../../../../../assets/sneakers.webp";
import WOMAN_SHOES_DEFAULT from "../../../../../assets/article-femme.webp";
import MAN_SHOES_DEFAULT from "../../../../../assets/man_shoes.webp";
import CLIMBING_SHOES_DEFAULT from "../../../../../assets/climbing_shoes.webp";

import clsx from "clsx";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import InputContainer from "../../../../../components/forms/inputs/inputContainer/inputContainer";
import { useDragScroll } from "../../../../../hooks/useDragScroll";
import {
  deleteFileFromLogistic,
  fetchLogisticList,
  updateLogisticMutation,
} from "../../../../../requests/logistic";
import { fetchOperationsList } from "../../../../../requests/operation";
import { updateProduct } from "../../../../../requests/product";
import translate from "../../../../../translation";
import { IOperation } from "../../../../../types/accounting.types";
import { DEFAULT_PRODUCT } from "../../../../../utils/logistic.init";
import {
  OPERATION_DEFAULT,
  OPERATION_TYPE_BY_GROUP,
} from "../../../../../utils/operation.init";
import ProductForm from "../productSection/productForm";
import style from "./tmp.module.scss";
import Button from "../../../../../components/Button/Button";
import { deleteFile } from "../../../../../requests/file";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../../../utils/toast.options";
import { useNavigate } from "react-router-dom";

interface FormProduct extends IProduct {
  selected?: boolean;
  hasError?: boolean;
}

export default function Tmp({
  fetchingMethod,
  trackId,
}: {
  fetchingMethod: (data: any) => Promise<any>;
  trackId: string;
}) {
  const [selectedProduct, setSelectedProduct] = useState<
    undefined | FormProduct
  >(undefined);

  const navigate = useNavigate();
  const [logistic, setLogistic] = useState<ILogistic>();

  const { data, refetch } = useQuery(
    ["cart-to-logistic", trackId, fetchingMethod],
    () => {
      return fetchingMethod({ params: { trackerId: trackId } });
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess(data) {
        if (selectedProduct) {
          changeSelectedProduct(
            selectedProduct.id,
            JSON.parse(JSON.stringify(data.logistic))
          );
        } else {
          changeSelectedProduct(
            data.logistic.productsList.at(0)?.id,
            JSON.parse(JSON.stringify(data.logistic))
          );
        }
      },
    }
  );
  const [needsUpdate, setNeedsUpdate] = useState(false);

  function logisticsAreDifferent(logistic1: ILogistic, logistic2: ILogistic) {
    return (
      JSON.stringify(logistic1) !==
      JSON.stringify(logistic2, (key, value) =>
        key === "selected" ? undefined : value
      )
    );
  }

  function productsAreDifferent(product1: IProduct, product2: IProduct) {
    return (
      JSON.stringify(product1) !==
      JSON.stringify(product2, (key, value) =>
        key === "selected" ? undefined : value
      )
    );
  }

  useEffect(() => {
    if (logistic && data?.logistic) {
      let up = logisticsAreDifferent(data.logistic, logistic);
      if (up !== needsUpdate) {
        setNeedsUpdate(up);
      }
      // if (up) {
      //   let logisticUpdate = Object.assign({}, logistic);
      //   logisticUpdate.productsList = logisticUpdate.productsList.map(p => ({...p, needsUpdate: }))
      // }
    }
  }, [logistic, data]);

  function updateLogisticProduct(product: IProduct, logisticData: ILogistic) {
    if (!logisticData || !logisticData.productsList) {
      return;
    }
    let productIndex = logisticData.productsList.findIndex(
      (p: FormProduct) => p.selected
    );
    if (productIndex < 0) {
      return;
    }
    logisticData.productsList[productIndex] = product;
    setLogistic({ ...logisticData });
    setSelectedProduct(product);
  }

  function getSelectedProduct(logisticData: ILogistic) {
    if (!logisticData || !logisticData.productsList) {
      return undefined;
    }
    let p = logisticData.productsList.find((p: FormProduct) => p.selected);
    return p;
  }

  function changeSelectedProduct(
    productId: string | undefined,
    logisticData: ILogistic
  ) {
    if (!logisticData) {
      return;
    }
    for (
      let productIndex = 0;
      productIndex < logisticData.productsList.length;
      productIndex++
    ) {
      const product: FormProduct = logisticData.productsList[productIndex];
      if (product.id === productId) {
        (
          logisticData.productsList[productIndex] as IProduct & {
            selected?: boolean;
          }
        ).selected = true;
      } else {
        (
          logisticData.productsList[productIndex] as IProduct & {
            selected?: boolean;
          }
        ).selected = false;
      }
    }
    setLogistic({ ...logisticData });
    setSelectedProduct(getSelectedProduct(logisticData));
  }

  const { mutate: addProduct } = useMutation(updateLogisticMutation, {
    onSuccess(data, variables, context) {
      refetch();
    },
    onError(error, variables, context) {
      toast.error("Une erreur est survenue", TOAST_ERROR_OPTIONS);
    },
  });

  const { mutate: updateCurrentProduct } = useMutation(updateProduct, {
    onSuccess(data, variables, context) {
      refetch();
    },
    onError(error, variables, context) {
      toast.error("Une erreur est survenue", TOAST_ERROR_OPTIONS);
    },
  });

  const { mutate: updateLogistic } = useMutation(updateLogisticMutation, {
    onSuccess(data, variables, context) {
      if (logistic?.productsList.every((p: FormProduct) => p.hasError === false)) {
        window.confirm("Voulez-vous générer un devis ?") ? navigate(`/quick-access/quote-edit/${trackId}`) : navigate(`/quick-access`)
      }
      refetch();
    },
    onError(error, variables, context) {
      toast.error("Une erreur est survenue", TOAST_ERROR_OPTIONS);
    },
  });

  const { mutate: deleteImage } = useMutation(deleteFile, {
    onSuccess(data, variables, context) {
      refetch();
    },
    onError(error, variables, context) {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS)
    },
  });

  if (!logistic) {
    return <></>;
  }

  return (
    <div className={style["tmp"]}>
      <div className={style["tmp-header"]}>
      <ContactContainer contact={logistic.contact} onChange={handleContactChange}/>
      <SaveWidget
          onClick={handleSaveClicked}
          disabled={false}
        />
      </div>
      <SliderContainer
        onAddClicked={handleAddProduct}
        logistic={logistic}
        onProductSelected={handleProductSelected}
        onProductDelete={handleDeleteProduct}
      />
      {selectedProduct && (
        <ProductTab
          trackId={trackId}
          onSubmit={handleProductSubmited}
          product={selectedProduct}
          onChange={handleProductChanged}
        />
      )}
    </div>
  );

  function handleAddProduct() {
    if (!logistic) {
      return;
    }
    let newLogistic = { ...logistic };
    newLogistic.productsList.push(DEFAULT_PRODUCT);
    changeSelectedProduct(DEFAULT_PRODUCT.id, newLogistic);
  }

  function handleDeleteProduct(product: IProduct) {
    if (!logistic) {
      return;
    }

    // Delete files
    if (
      product.incomingPicturesList &&
      product.incomingPicturesList.length > 0
    ) {
      product.incomingPicturesList.forEach((f) => deleteImage(f));
    }

    let newLogistic = { ...logistic };

    if (!product.id || product.id === "") {
      // If product has no ID, just remove last product from list. No need to update DB.
      newLogistic.productsList.pop();
      setLogistic(newLogistic);

      handleProductSelected(
        newLogistic.productsList[newLogistic.productsList.length - 1]
      );
    } else {
      // If product has an ID, remove product from list and update database.
      let productIndex = newLogistic.productsList.findIndex(
        (p) => p.id === product.id
      );
      if (productIndex > 0) {
        newLogistic.productsList.splice(productIndex, 1);
      }
      updateLogistic({
        logistic: newLogistic,
        updateQuote: true,
        setShippingToDelivered: false,
      });
    }
  }

  function handleProductChanged(product: IProduct) {
    if (!logistic) {
      return;
    }
    updateLogisticProduct(product, logistic);
  }


  function handleContactChange(contact: IContact): void {
    if (!logistic) {
      return;
    }
    let updatedLogistic = {...logistic, contact};
    setLogistic(updatedLogistic);
  }

  async function handleSaveClicked() {
    if (!logistic) {
      return;
    }
    let updatedLogistic = { ...logistic };

    if (!updatedLogistic.productsList) {
      updatedLogistic.productsList = [];
    }

    for (
      let productIndex = 0;
      productIndex < updatedLogistic.productsList.length;
      productIndex++
    ) {
      const product = updatedLogistic.productsList[productIndex];
      (updatedLogistic.productsList[productIndex] as FormProduct).hasError =
        !(await isProductFormValid(product));
    }

    setLogistic(updatedLogistic);
    updateLogistic({
      logistic,
      updateQuote: true,
      setShippingToDelivered: true,
    });
  }

  function handleProductSubmited(product: IProduct) {
    if (!logistic) {
      return;
    }
    if (product.id && product.id !== "") {
      updateCurrentProduct(product);
    } else {
      let newLogistic = { ...logistic };
      newLogistic.productsList[newLogistic.productsList.length - 1] = product;
      addProduct({
        logistic: newLogistic,
        updateQuote: true,
        setShippingToDelivered: false,
      });
    }
  }

  function handleProductSelected(product: FormProduct): void {
    if (!logistic) {
      return;
    }
    changeSelectedProduct(product.id, logistic);
  }

  async function isProductFormValid(product: FormProduct) {
    return (
      product.category &&
      product.category.trim() !== "" &&
      product.color &&
      product.color.trim() !== "" &&
      product.brand &&
      product.brand.trim() !== "" &&
      product.productGroup &&
      product.productGroup.trim() !== "" &&
      product.operationGroup &&
      product.operationGroup.trim() !== "" &&
      product.incomingPicturesList &&
      product.incomingPicturesList.length > 0 &&
      product.workshopId &&
      product.workshopId.length >= 7 &&
      !(await workshopIdIsInvalid(product.workshopId, product.id)) &&
      product.operationsList &&
      product.operationsList.length > 0
    );
  }

  async function workshopIdIsInvalid(
    workshopId: string | undefined,
    productId: string | undefined
  ): Promise<boolean> {
    const response = await fetchLogisticList(
      `?productsList.workshopId=${workshopId}`
    );
    if (response) {
      const logisticsInProgress = response.logisticsList.filter(
        (logistic) => logistic.status !== "LOGISTIC_DONE"
      );

      if (!productId && logisticsInProgress.length > 0) {
        return true;
      }

      if (
        productId &&
        logisticsInProgress.some((logistic) =>
          logistic.productsList.some(
            (product) =>
              product.id !== productId && product.workshopId === workshopId
          )
        )
      ) {
        return true;
      }
    }
    if (!workshopId || workshopId.length < 7) {
      return true;
    }
    return false;
  }
}

function ContactContainer({ contact, onChange }: { contact: IContact, onChange: (contact: IContact) => void }) {
  return (
    <div className={style["contact-container"]}>
      <div className={style["contact-inputs-row"]}>
        <InputContainer>
          <label>Prénom</label>
          <input
            className={style["operation-edit-input"]}
            type="text"
            name="givenname"
            onChange={handleChange}
            value={contact.givenname}
          />
        </InputContainer>
        <InputContainer>
          <label>Nom</label>
          <input
            className={style["operation-edit-input"]}
            type="text"
            name="familyname"
            onChange={handleChange}
            value={contact.familyname}
          />
        </InputContainer>
      </div>

      <div className={style["contact-inputs-row"]}>
        <InputContainer>
          <label>Téléphone</label>
          <input
            className={style["operation-edit-input"]}
            type="tel"
            name="phone"
            onChange={handleChange}
            value={contact.phone}
          />
        </InputContainer>
        <InputContainer>
          <label>Email</label>
          <input
            className={style["operation-edit-input"]}
            type="email"
            name="email"
            onChange={handleChange}
            value={contact.email}
          />
        </InputContainer>
      </div>
    </div>
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = {...contact, [event.target.name] : event.target.value};
    data.displayname = `${data.givenname} ${data.familyname}`;
    onChange && onChange(data)
  }
}

function SliderContainer({
  logistic,
  onProductSelected,
  onAddClicked,
  onProductDelete,
}: {
  logistic: ILogistic;
  onProductSelected: (product: FormProduct) => void;
  onAddClicked: () => void;
  onProductDelete: (product: IProduct) => void;
}) {
  const [ref] = useDragScroll();

  return (
    <div ref={ref} className={style["slider-container"]}>
      <div className={style["slider-content"]}>
        {logistic.productsList.map((product: IProduct) => (
          <ProductWidget
            key={product.id as string}
            product={product}
            onClick={handleProductSelected}
            onDeleteClick={handleDeleteClicked}
          />
        ))}

        <AddWidget
          onClick={onAddClicked}
          disabled={
            logistic.productsList.at(-1)?.id === undefined ||
            logistic.productsList.at(-1)?.id === ""
          }
        />
      </div>
    </div>
  );

  function handleDeleteClicked(product: IProduct): void {
    onProductDelete && onProductDelete(product);
  }

  function handleProductSelected(product: IProduct): void {
    onProductSelected && onProductSelected(product);
  }
}

function ProductTab({
  product,
  onSubmit,
  onChange,
  trackId,
}: {
  product: IProduct;
  onSubmit: (product: IProduct) => void;
  onChange: (product: IProduct) => void;
  trackId: string;
}) {
  const [validationError, setValidationError] = useState<string[]>([]);

  useEffect(() => {
    async function handleValidateForm(product: IProduct) {
      const newValidationError = [...validationError];
      newValidationError.splice(0);
      (!product.color || product.color === "") &&
        newValidationError.push("color");
      (!product.brand || product.brand === "") &&
        newValidationError.push("brand");
      (!product.productGroup || product.productGroup === "") &&
        newValidationError.push("productGroup");
      (!product.operationGroup || product.operationGroup === "") &&
        newValidationError.push("operationGroup");
      if (!product.size) {
        product.size = 0;
      }
      (!product.workshopId ||
        product.workshopId.length < 7 ||
        (await workshopIdIsInvalid(product.workshopId, product.id))) &&
        newValidationError.push("workshopId");
      !product.category && newValidationError.push("category");
      setValidationError(newValidationError);
    }
    handleValidateForm(product);
  }, [product]);

  return (
    <div className={style["product-tab"]}>
      <div className={style["product-tab-content"]}>
        <ProductInformationSection
          trackId={trackId}
          product={product}
          onChange={onChange}
          validationError={validationError}
          setValidationError={setValidationError}
        />
        <OperationsForm product={product} onChange={handleChange} />
      </div>
    </div>
  );

  function handleChange(product: IProduct): void {
    onChange && onChange(product);
  }

  function handleSubmit(product: IProduct) {
    handleValidateForm(product);
  }

  async function handleValidateForm(product: IProduct) {
    const newValidationError = [...validationError];
    newValidationError.splice(0);
    (!product.color || product.color === "") &&
      newValidationError.push("color");
    (!product.brand || product.brand === "") &&
      newValidationError.push("brand");
    (!product.productGroup || product.productGroup === "") &&
      newValidationError.push("productGroup");
    (!product.operationGroup || product.operationGroup === "") &&
      newValidationError.push("operationGroup");
    if (!product.size) {
      product.size = 0;
    }
    (!product.workshopId ||
      product.workshopId.length < 7 ||
      (await workshopIdIsInvalid(product.workshopId, product.id))) &&
      newValidationError.push("workshopId");
    !product.category && newValidationError.push("category");
    setValidationError(newValidationError);
    if (newValidationError.length > 0) {
      if (
        newValidationError.length === 1 &&
        newValidationError.includes("workshopId")
      ) {
        window.alert("Numéro de ticket existant ou invalide !");
      } else {
        window.alert("Veuillez remplir les champs obligatoires !");
      }
    } else {
      onSubmit && onSubmit(product);
    }
  }

  async function workshopIdIsInvalid(
    workshopId: string | undefined,
    productId: string | undefined
  ): Promise<boolean> {
    const response = await fetchLogisticList(
      `?productsList.workshopId=${workshopId}`
    );
    if (response) {
      const logisticsInProgress = response.logisticsList.filter(
        (logistic) => logistic.status !== "LOGISTIC_DONE"
      );

      if (!productId && logisticsInProgress.length > 0) {
        return true;
      }

      if (
        productId &&
        logisticsInProgress.some((logistic) =>
          logistic.productsList.some(
            (product) =>
              product.id !== productId && product.workshopId === workshopId
          )
        )
      ) {
        return true;
      }
    }
    if (!workshopId || workshopId.length < 7) {
      return true;
    }
    return false;
  }
}

function OperationsForm({
  product,
  onChange,
}: {
  product: IProduct;
  onChange: (product: IProduct) => void;
}) {
  const { data, isFetching, error, refetch } = useQuery(
    "quick-access-operationListSelect",
    () =>
      fetchOperationsList(
        `productGroup=${product.productGroup}&operationGroup=${product.operationGroup}`
      )
  );

  const [operations, setOperations] = useState<IOperation[] | undefined>(
    undefined
  );

  useEffect(() => {
    refetch();
  }, [product.productGroup, product.operationGroup]);

  useEffect(() => {
    if (product) {
      setOperations(product.operationsList);
    }
  }, [product]);

  return (
    <div className={clsx(style["operation-form"])}>
      {(operations || []).map((operation: IOperation, index: number) => (
        <OperationEntry
          key={`product-${product.id || ""}-${index}-operation`}
          operationGroup={product.operationGroup}
          productCategory={product.category || "CORDO_AUTRE"}
          datalist={data?.operations || []}
          operation={operation}
          onChange={(updatedOperation) => {
            updateOperationAtIndex(updatedOperation, index);
          }}
          onDelete={(operation: IOperation) =>
            handleDeleteOperation(operation, index)
          }
        />
      ))}
      <AddOperationButton onClick={handleAddOperation} />
    </div>
  );

  function handleDeleteOperation(operation: IOperation, index: number) {
    let tmpOperations = operations ? [...operations] : [];
    if (tmpOperations.length > index) {
      tmpOperations.splice(index, 1);
    }
    setOperations(tmpOperations);
    onChange({ ...product, operationsList: tmpOperations });
  }

  function handleAddOperation(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    event.preventDefault();
    setOperations([...(operations || []), OPERATION_DEFAULT]);
  }

  function updateOperationAtIndex(operation: IOperation, index: number) {
    let tmpOperations = operations ? [...operations] : [];
    if (tmpOperations.length > index) {
      tmpOperations[index] = operation;
    } else {
      tmpOperations.push(operation);
    }
    setOperations(tmpOperations);
    onChange({ ...product, operationsList: tmpOperations });
  }
}

function AddOperationButton({
  onClick,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) {
  return (
    <button
      className={clsx(style["operation-entry"], style["add-operation-button"])}
      onClick={onClick}
    >
      <AddCircleOutline
        className={clsx(style["widget-preview"], style["add-icon"])}
      />
      Nouvelle opération
    </button>
  );
}

function OperationEntry({
  productCategory,
  operationGroup,
  operation,
  datalist,
  onChange,
  onDelete,
}: {
  operationGroup: string;
  productCategory: string;
  operation: IOperation;
  datalist: IOperation[];
  onChange: (operation: IOperation) => void;
  onDelete: (operation: IOperation) => void;
}) {
  return (
    <div className={clsx(style["operation-entry"])}>
      <InputContainer className={clsx(style["operation-description"])}>
        <label>Opération</label>
        <input
          className={style["operation-edit-input"]}
          type="text"
          name="description"
          onChange={handleChange}
          value={operation.description}
          autoComplete="off"
          list="operation-description"
        />
        <datalist id={"operation-description"}>
          {datalist &&
            datalist.map((operation: IOperation, key: number) => {
              if (!operation.canceled) {
                return (
                  <option key={key} className={style["datalist-item"]}>
                    {operation.description || ""}
                  </option>
                );
              }
            })}
        </datalist>
      </InputContainer>

      <InputContainer className={clsx(style["operation-price"])}>
        <label>Prix</label>
        <input
          className={style["operation-edit-input"]}
          type="text"
          name="price"
          onChange={handleChange}
          value={operation.price}
        />
      </InputContainer>

      <InputContainer className={clsx(style["operation-type"])}>
        <label>Type d'opération</label>
        <select
          className={style["operation-edit-input"]}
          name="operationType"
          onChange={handleOperationTypeSelected}
          value={operation.operationType}
        >
          <option>Sélectionnez un type d'opération</option>
          {(
            OPERATION_TYPE_BY_GROUP[
              operationGroup as keyof typeof OPERATION_TYPE_BY_GROUP
            ] || []
          ).map((r) => (
            <option key={r} value={r}>
              {translate(r)}
            </option>
          ))}
        </select>
      </InputContainer>
      <div className={style["delete-icon-container"]} onClick={handleDelete}>
        <div className={style["delete-icon"]}>
          <Delete />
        </div>
      </div>
    </div>
  );

  function handleOperationTypeSelected(
    event: React.ChangeEvent<HTMLSelectElement>
  ): void {
    let op: IOperation = {
      ...operation,
      operationType: event.target.value,
    };
    onChange && onChange(op);
  }

  function handleDelete(): void {
    onDelete && onDelete(operation);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target;
    let baseOperation: IOperation | undefined = undefined;
    if (name === "description") {
      baseOperation = datalist.find((o) => o.description === value);
    }
    if (baseOperation) {
      onChange && onChange(baseOperation);
    } else {
      onChange && onChange({ ...operation, [name]: value });
    }
  }
}

function ProductInformationSection({
  product,
  onChange,
  validationError,
  setValidationError,
  trackId,
}: {
  product: IProduct;
  onChange: (product: IProduct) => void;
  validationError: string[];
  setValidationError: (validationError: string[]) => void;
  trackId: string;
}) {
  return (
    <div className={style["product-section"]}>
      <ProductForm
        product={product}
        validationError={validationError}
        setValidationError={setValidationError}
        onChange={handleChange}
        trackId={trackId}
      />
    </div>
  );

  function handleChange(product: IProduct) {
    onChange && onChange({ ...product });
  }
}

function AddWidget({
  onClick,
  disabled = false,
}: {
  onClick: () => void;
  disabled: boolean;
}) {
  return (
    <div
      className={clsx(style["product-widget"], style["add-widget"], {
        [style["disabled"]]: disabled,
      })}
      onClick={() => !disabled && onClick()}
    >
      <AddCircleOutline
        className={clsx(style["widget-preview"], style["add-icon"])}
      />
    </div>
  );
}

function SaveWidget({
  onClick,
  disabled = false,
}: {
  onClick: () => void;
  disabled: boolean;
}) {
  return (
    <div
      className={clsx(style["product-widget"], style["save-widget"], {
        [style["disabled"]]: disabled,
      })}
      onClick={() => !disabled && onClick()}
    >
      <SaveOutlined
        className={clsx(style["widget-preview"], style["save-icon"])}
      />
    </div>
  );
}

function ProductWidget({
  product,
  selected = false,
  onClick,
  onDeleteClick,
}: {
  product: FormProduct;
  selected?: boolean;
  onClick: (product: IProduct) => void;
  onDeleteClick: (product: IProduct) => void;
}) {
  return (
    <div
      className={clsx(style["product-widget"], {
        [style["selected"]]: product.selected,
        [style["error"]]: product.hasError,
        [style["valid"]]: product.hasError === false,
      })}
      onClick={handleClick}
    >
      <div className={clsx(style["product-wiget-content"])}>
        <div className={style["product-info"]}>
          <span className={style["product-info-brand"]}>{product.brand}</span>

          <div className={style["workshop-id-container"]}>
            <div>{product.workshopId || ""}</div>
            <LocalOfferOutlined />
          </div>
        </div>

        <img
          draggable={false}
          className={style["widget-preview"]}
          src={getImagePreviewUri(product)}
        />
      </div>
      <div
        className={style["delete-icon-container"]}
        onClick={() => onDeleteClick(product)}
      >
        <Delete />
      </div>
    </div>
  );

  function handleClick(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void {
    event.preventDefault();
    onClick && onClick(product);
  }
}

function getImagePreviewUri(product: IProduct) {
  let imageUrl = HANDBAG_DEFAULT;
  if (product.productGroup === "SHOES") {
    switch (product.operationGroup) {
      case "SHOES_MAN":
        imageUrl = MAN_SHOES_DEFAULT;
        break;
      case "SHOES_WOMAN":
        imageUrl = WOMAN_SHOES_DEFAULT;
        break;
      case "SHOES_SNEAKERS":
        imageUrl = SNEAKERS_DEFAULT;
        break;
      case "SHOES_CLIMBING":
        imageUrl = CLIMBING_SHOES_DEFAULT;
        break;
      default:
        break;
    }
  }
  // product.productGroup === "LEATHER" ? HANDBAG_DEFAULT : SHOES_DEFAULT;
  if (product?.incomingPicturesList && product.incomingPicturesList.at(0)) {
    imageUrl = `${
      process.env.REACT_APP_API_URL
    }/file/${product.incomingPicturesList.at(0)}`;
  }
  return imageUrl;
}

