import { ILogistic, IProduct } from "../../../types/logistic.types";
import style from "./item.module.scss";
import { useState } from "react";
import getStatusColor from "../../../translation/statusColor";
import { Delete, Edit, Launch } from "@mui/icons-material";
import translate from "../../../translation";
import { useModalComponent } from "../../../context/modalComponent.context";
import LogisticModal from "../../../components/modals/logisticModal/logisticModal";
import WorkshopId from "../../../components/workshopId/workshopId";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import { getColorCode } from "../../../utils/utils";
import { useConfirmModal } from "../../../context/confirmationModalContext";
import ConfirmModalComp from "../../../components/confirmationModal/ConfirmModalComp";

const Item = ({
  data,
  zIndex,
  onDelete,
  refetch,
}: {
  data: ILogistic;
  zIndex: number;
  onDelete: (id: string) => void;
  refetch: () => void;
}) => {
  const [hover, setHover] = useState<Boolean>(false);
  const { setModalIsOpen, setModalChildren } = useModalComponent();
  const { setConfirmModalIsOpen, setConfirmModalChildren } = useConfirmModal();

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: () => {
        openLogisticModal();
      },
    },
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/logistic/edit/${data?.id}`,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      onClick: () => handleConfirmDelete(),
    },
  ];

  return (
    <div
      className={style["item"]}
      style={{
        backgroundColor: `${hover ? "#f2f6fb" : ""}`,
      }}
    >
      <div className={style["item-top"]}>
        <div
          className={style["item-left"]}
          onClick={openLogisticModal}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <div className={`${style["item-element"]} ${style["item-coord"]}`}>
            <span className={style["displayname"]}>
              {data.contact.displayname}
            </span>
            <span>{data.contact.organization}</span>
            <span>{data.contact.phone}</span>
            <span>{data.contact.email}</span>
          </div>
          <div
            className={`${style["item-element"]} ${style["item-date"]}`}
            title={new Date(data.createdAt).toLocaleString()}
          >
            {new Date(data.createdAt).toLocaleDateString()}
          </div>
          <div className={`${style["item-element"]} ${style["item-product"]}`}>
            <div className={style["list"]}>
              {data.productsList.map((value: IProduct, index: number) => {
                return (
                  <div className={style["product-desc"]} key={index}>
                    <div className={style["brand"]}>{value.brand}</div>
                    <div className={style["color"]}>
                      <div
                        className={style["color-box"]}
                        style={{
                          backgroundColor: `${getColorCode(value.color)}`,
                        }}
                      ></div>
                      <span>({value.color || "non défini"})</span>
                    </div>
                    <WorkshopId
                      workshopId={value.workshopId}
                      size={"xx-small"}
                      type={!value.workshopId?.startsWith("1-1-") ? "pro" : ""}
                    />
                  </div>
                );
              })}
              {data.crossSell &&
                data.crossSell.crossSellItems &&
                data.crossSell.crossSellItems.length > 0 && (
                  <div className={style["product-desc"]}>
                    <div className={style["brand"]}>Articles Cross-selling</div>
                    <div className={style["color"]}>
                      {data.crossSell.crossSellItems.length}
                    </div>
                    <WorkshopId
                      workshopId={data.crossSell.workshopId}
                      size={"xx-small"}
                      type={"cross-sell"}
                    />
                  </div>
                )}
            </div>
          </div>
          <div className={`${style["item-element"]} ${style["status-box"]}`}>
            <span
              className={style["status"]}
              style={{ backgroundColor: `${getStatusColor(data.status)}` }}
            >
              {translate(data.status)}
            </span>
          </div>
        </div>

        <div className={style["tools"]}>
          <MoreTools options={MORE_TOOLS_OPTIONS} zIndex={zIndex} />
        </div>
      </div>
    </div>
  );

  function handleConfirmDelete() {
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={style["delete-button"]}
        title={"Voulez vous supprimer ce document ?"}
        confirmText="Supprimer"
        onConfirm={handleDelete}
      />
    );
    setConfirmModalIsOpen(true);
  }

  function handleDelete() {
    onDelete(data.id);
    refetch();
  }

  async function openLogisticModal() {
    setModalIsOpen(true);
    setModalChildren(<LogisticModal id={data.id} requiredActions={false} />);
  }
};

export default Item;
